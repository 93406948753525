import React, { useState, useRef, useEffect } from 'react';
import { useIntl } from 'react-intl';

//Hooks
import useClickOutside from '../../../hooks/useClickOutside';

//Components
import Input from '../../Forms/InputFloatLabel';
import Buttons from '../../Forms/Buttons';

//Assets
import tipIcon from 'assets/images/global/tip-icon.svg';
import arrowIcon from 'assets/images/topbar/select-arrow.svg';

//Styles
import * as styled from './modals-styles';

interface IBankAccount {
  bankAccountId: number,
  bankName: string,
  branchCode: string,
  accountNumber: string,
  bankCode?: string,
  bankIcon?: string;
  bankAccountIdToActivate: string | null,
  transferPlatformType?: string,
  transferPlatformCode?: string,
  isActive?: boolean,
}

interface Bank {
  bankName: string;
  bankCode: string;
  bankLogo?: string;
}

type TProps = {
  setIsAddingAccount?: any;
  withdrawalAccounts: IBankAccount[];
  selectedLabel: string;
  handler?: any;
}

const SelectBankAccountButtonModal = ({ setIsAddingAccount, withdrawalAccounts, selectedLabel, handler }: TProps) => {
  const [isMounted, setIsMounted] = useState(false);
  const [showRequestPaymentModal, setShowRequestPaymentModal] = useState(false);
  const [isForeigner, setIsForeigner] = useState(false);
  const [isSelectedABankcAccount, setIsSelectedABankAccount] = useState(false);

  const [bankAccounts, setBankAccounts] = useState<IBankAccount[]>([]);

  const [bankAccountId, setBankAccountId] = useState(0);

  const [selectedRadioButton, setSelectedRadioButton] = useState<string>('');
  const [businessId, setBusinessId] = useState(0);
  const [wantedBankAccount, setWantedBankAccount] = useState('');

  const transferPlataformTypeArray = ['', 'SWIFT', 'BIC', 'IBAN'];

  const selectBankAccountModalRef = useRef<HTMLDivElement>(null);
  const intl = useIntl();

  useEffect(() => {
    if(wantedBankAccount !== '') {
      let filteredBanksAccount = withdrawalAccounts.filter((bankAccount: IBankAccount) => {

        if(bankAccount.bankName) {
          console.log(bankAccount.bankName, "teste")
          return bankAccount.bankName.toLowerCase().includes(wantedBankAccount.toLowerCase())
        }
      });

      setBankAccounts(filteredBanksAccount);
    }

  }, [wantedBankAccount]);

  function handleShowModal() {
    if(selectBankAccountModalRef.current) {
      setIsMounted(false);
      setShowRequestPaymentModal(true);
    } else {
      setIsMounted(true);
      setShowRequestPaymentModal(true);
    }

  }

  useClickOutside(selectBankAccountModalRef, isMounted, setIsMounted);

  function handleClickBankAccount(bankAccountId: number) {
    handler(bankAccountId);
    setIsSelectedABankAccount(true);

    setIsMounted(!isMounted);

    setTimeout(() => {
      setShowRequestPaymentModal(false)
    }, 300);
  }

  return (
    <styled.RelativeContainer>
      <styled.LabelSelectPlan onClick={handleShowModal}>
        <p>{isSelectedABankcAccount ? selectedLabel : intl.formatMessage({ id: "home.withdrawall.select.bank.account.placeholder" })}</p>
        
        <img src={arrowIcon} alt="arrow" />
      </styled.LabelSelectPlan>
      
      {showRequestPaymentModal && <styled.SelectBankAccountContainer 
        style={isMounted ? { animation: 'scaleModal .2s ease forwards' } : { animation: 'reveserScameModal .1s ease-out forwards' }} 
        ref={selectBankAccountModalRef}
        onAnimationEnd={() => { if (!isMounted) setShowRequestPaymentModal(false) }}
      >

        <div className="header-container">
          <div>
            <Input required={false} mode="search" name="wantedStore" value={wantedBankAccount} placeholder={intl.formatMessage({ id: "home.withdrawall.search.bank" })} handler={setWantedBankAccount} />
          </div>
        </div>

        <div className="buttons-container">
          {!isForeigner && (wantedBankAccount !== '' ? bankAccounts : withdrawalAccounts).map((account) => {
            return (<div className="button__item" key={account.branchCode} onClick={() => handleClickBankAccount(account.bankAccountId)}>
                    <div className="flex-buttons-container">
                      <Buttons mode='simple' label={account.bankName} handler={() => {}} /> 
                      <img src={account.bankIcon} alt={account.bankName} />
                    </div>
                    
                    <span>{`${account.branchCode} / ${account.accountNumber}`}</span>
              </div>)
          })}
        </div>

        <div className="add-bank-account-container">
          <Buttons mode="unfilled" label={intl.formatMessage({ id: "home.withdrawall.change.bank.details" })} handler={() => setIsAddingAccount(true)} />
        </div>

        <styled.Tip>
          <div className="tip-intern-container">
            <div>
              <img src={tipIcon} alt="tip" />
              <p>{intl.formatMessage({ id: "request.payment.tip.title" })}</p>
            </div>
            
            <p>{intl.formatMessage({ id: "bank.account.select.tip.text" })}</p>
          </div>
        </styled.Tip>
      </styled.SelectBankAccountContainer>}
    </styled.RelativeContainer>
  )
} 

export default SelectBankAccountButtonModal;
