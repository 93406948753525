const axios = require("axios");

interface IPagination {
  pageSize: number;
  pageIndex?: number;
  query?: string;
}

interface IRefund {
  paymentId: number;
  amount: number;
  storeId: number;
}

interface ISearch {
  type: string;
  value: string;
}

class OrderService {
  async getRefunds(paymentId: number, storeId: string) {
    const session = JSON.parse(localStorage.getItem("session") || "{}");
    let id = session.entityType === "Administrator" ? storeId : session.storeId;

    return axios.get(
      `${process.env.REACT_APP_API_BASE_URL}/stores/${id}/payments/${paymentId}/refunds`,
      {
        headers: {
          Authorization: `Bearer ${session.token}`,
          "Content-Type": "application/json",
        },
      }
    );
  }

  async sendRefund({ paymentId, amount, storeId }: IRefund) {
    const session = JSON.parse(localStorage.getItem("session") || "{}");

    let storeIdToUse = 0;

    if (session.entityType === "Administrator") {
      storeIdToUse = storeId;
    } else {
      storeIdToUse = storeId;
    }

    return axios.post(
      `${process.env.REACT_APP_API_BASE_URL}/stores/${storeIdToUse}/payments/${paymentId}/refunds`,
      { amount: parseFloat(amount.toString()) },
      {
        headers: {
          Authorization: `Bearer ${session.token}`,
          "Content-Type": "application/json",
        },
      }
    );
  }

  async allowTokenizedOrder(number: string | undefined) {
    const session = JSON.parse(localStorage.getItem("session") || "{}");
    return axios.post(
      `${process.env.REACT_APP_API_BASE_URL}/stores/${session.storeId}/payment/${number}/process`,
      {},
      {
        headers: {
          Authorization: `Bearer ${session.token}`,
          "Content-Type": "application/json",
        },
      }
    );
  }

  async getByNumber(number: string | undefined, storeId: string | undefined) {
    const session = JSON.parse(localStorage.getItem("session") || "{}");

    if (session.entityType === "Administrator") {
      return axios.get(
        `${process.env.REACT_APP_API_BASE_URL}/admin/store/${storeId}/orders/${number}`,
        {
          headers: {
            Authorization: `Bearer ${session.token}`,
          },
        }
      );
    } else {
      if (session.entityType === "Business") {
        return axios.get(
          `${process.env.REACT_APP_API_BASE_URL}/business/${session.businessId}/orders/${number}`,
          {
            headers: {
              Authorization: `Bearer ${session.token}`,
            },
          }
        );
      } else {
        return axios.get(
          `${process.env.REACT_APP_API_BASE_URL}/stores/${session.storeId}/orders/${number}`,
          {
            headers: {
              Authorization: `Bearer ${session.token}`,
            },
          }
        );
      }
    }
  }

  async get({ pageSize, pageIndex = 1 }: IPagination) {
    const session = JSON.parse(localStorage.getItem("session") || "{}");
    if (session.entityType === "Business") {
      return axios.get(
        `${process.env.REACT_APP_API_BASE_URL}/business/${session.businessId}/orders?pageSize=${pageSize}&pageIndex=${pageIndex}`,
        {
          headers: {
            Authorization: `Bearer ${session.token}`,
          },
        }
      );
    }
    return axios.get(
      `${process.env.REACT_APP_API_BASE_URL}/stores/${session.storeId}/orders?pageSize=${pageSize}&pageIndex=${pageIndex}`,
      {
        headers: {
          Authorization: `Bearer ${session.token}`,
        },
      }
    );
  }
  
  async getDistinctRecentOrders({ pageSize, pageIndex = 1 }: IPagination) {
    const session = JSON.parse(localStorage.getItem("session") || "{}");
    
    if (session.entityType === "Administrator") {
      return axios.get(
        `${process.env.REACT_APP_API_BASE_URL_V2}/orders?businessId=${session.businessId}&pageSize=${pageSize}&pageIndex=${pageIndex}`,
        {
          headers: {
            Authorization: `Bearer ${session.token}`,
          },
        }
      );
    } 
    
    else if (session.entityType === "Business") {
      return axios.get(
        `${process.env.REACT_APP_API_BASE_URL_V2}/orders?storeId=${session.storeId}&pageSize=${pageSize}&pageIndex=${pageIndex}`,
        {
          headers: {
            Authorization: `Bearer ${session.token}`,
          },
        }
        );
      }
      else if (session.entityType === "Store") {
        console.log('entrou novo')
      return axios.get(
        `${process.env.REACT_APP_API_BASE_URL_V2}/orders?pageSize=${pageSize}&pageIndex=${pageIndex}`,
        {
          headers: {
            Authorization: `Bearer ${session.token}`,
          },
        }
      );
    }
    // else {
    //   return axios.get(
    //     // `${process.env.REACT_APP_API_BASE_URL}/stores/${session.storeId}/distinct_orders?pageSize=${pageSize}&pageIndex=${pageIndex}`,
    //     `https://premeapi-staging.premepay.com/v2/${session.storeId}/orders?entityType=${session.entityType}&pageSize=${pageSize}&pageIndex=${pageIndex}`,
    //     {
    //       headers: {
    //         Authorization: `Bearer ${session.token}`,
    //       },
    //     }
    //   );
    // }

  }

  async getDistinctRecentOrdersForHomePage({ pageSize, pageIndex = 1 }: IPagination) {
    const session = JSON.parse(localStorage.getItem("session") || "{}");
    
    if (session.entityType === "Administrator") {
      return axios.get(
        `${process.env.REACT_APP_API_BASE_URL}/admin/distinct_orders?pageSize=${pageSize}&pageIndex=${pageIndex}`,
        {
          headers: {
            Authorization: `Bearer ${session.token}`,
          },
        }
      );
    } 
    
    else if (session.entityType === "Business") {
      return axios.get(
        `${process.env.REACT_APP_API_BASE_URL}/business/${session.businessId}/distinct_orders?pageSize=${pageSize}&pageIndex=${pageIndex}`,
        {
          headers: {
            Authorization: `Bearer ${session.token}`,
          },
        }
        );
      }
      else if (session.entityType === "Store") {
        console.log('entrou novo')
      return axios.get(
        `${process.env.REACT_APP_API_BASE_URL}/stores/${session.storeId}/distinct_orders?pageSize=${pageSize}&pageIndex=${pageIndex}`,
        {
          headers: {
            Authorization: `Bearer ${session.token}`,
          },
        }
      );
    }
  }

  async getFilteredOrders({ pageSize, pageIndex = 1, query }: IPagination) {
    const session = JSON.parse(localStorage.getItem("session") || "{}");

    if (session.entityType === "Administrator") {
      return axios.get(
        `${process.env.REACT_APP_API_BASE_URL_V2}/orders?businessId=${session.businessId}&pageSize=${pageSize}&pageIndex=${pageIndex}${query}`,
        {
          headers: {
            Authorization: `Bearer ${session.token}`,
          },
        }
      );
    } else {
      if (session.entityType === "Business") {
        return axios.get(
          `${process.env.REACT_APP_API_BASE_URL_V2}/orders?storeId=${session.storeId}&pageSize=${pageSize}&pageIndex=${pageIndex}${query}`,
          {
            headers: {
              Authorization: `Bearer ${session.token}`,
            },
          }
        );
      }
    }

    return axios.get(
      `${process.env.REACT_APP_API_BASE_URL_V2}/orders?pageSize=${pageSize}&pageIndex=${pageIndex}${query}`,
      {
        headers: {
          Authorization: `Bearer ${session.token}`,
        },
      }
    );
  }

  async getAllDistinctOrders() {
    const session = JSON.parse(localStorage.getItem("session") || "{}");
    
    if (session.entityType === "Administrator") {
      return axios.get(
        `${process.env.REACT_APP_API_BASE_URL}/admin/distinct_orders`,
        {
          headers: {
            Authorization: `Bearer ${session.token}`,
          },
        }
      );
    } else {
      if (session.entityType === "Business") {
        return axios.get(
          `${process.env.REACT_APP_API_BASE_URL}/business/${session.businessId}/distinct_orders`,
          {
            headers: {
              Authorization: `Bearer ${session.token}`,
            },
          }
        );
      }
    }

    return axios.get(
      `${process.env.REACT_APP_API_BASE_URL}/stores/${session.storeId}/distinct_orders`,
      {
        headers: {
          Authorization: `Bearer ${session.token}`,
        },
      }
    );
  }

  async getOverall() {
    const session = JSON.parse(localStorage.getItem("session") || "{}");
    if (session.entityType === "Business") {
      return axios.get(
        `${process.env.REACT_APP_API_BASE_URL}/business/${session.businessId}/overall`,
        {
          headers: {
            Authorization: `Bearer ${session.token}`,
          },
        }
      );
    }
    return axios.get(
      `${process.env.REACT_APP_API_BASE_URL}/stores/${session.storeId}/overall`,
      {
        headers: {
          Authorization: `Bearer ${session.token}`,
        },
      }
    );
  }

  async getCountPerMonth() {
    const session = JSON.parse(localStorage.getItem("session") || "{}");
    if (session.entityType === "Business") {
      return axios.get(
        `${process.env.REACT_APP_API_BASE_URL}/business/${session.businessId}/per_month`,
        {
          headers: {
            Authorization: `Bearer ${session.token}`,
          },
        }
      );
    }
    return axios.get(
      `${process.env.REACT_APP_API_BASE_URL}/stores/${session.storeId}/per_month`,
      {
        headers: {
          Authorization: `Bearer ${session.token}`,
        },
      }
    );
  }

  async getGrossSales() {
    const session = JSON.parse(localStorage.getItem("session") || "{}");
    if (session.entityType === "Business") {
      return axios.get(
        `${process.env.REACT_APP_API_BASE_URL}/business/${session.businessId}/gross_sales`,
        {
          headers: {
            Authorization: `Bearer ${session.token}`,
          },
        }
      );
    }
    return axios.get(
      `${process.env.REACT_APP_API_BASE_URL}/stores/${session.storeId}/gross_sales`,
      {
        headers: {
          Authorization: `Bearer ${session.token}`,
        },
      }
    );
  }

  async netNewCustomers() {
    const session = JSON.parse(localStorage.getItem("session") || "{}");
    if (session.entityType === "Business") {
      return axios.get(
        `${process.env.REACT_APP_API_BASE_URL}/business/${session.businessId}/new_customers_quantity`,
        {
          headers: {
            Authorization: `Bearer ${session.token}`,
          },
        }
      );
    }
    return axios.get(
      `${process.env.REACT_APP_API_BASE_URL}/stores/${session.storeId}/new_customers_quantity`,
      {
        headers: {
          Authorization: `Bearer ${session.token}`,
        },
      }
    );
  }

  async cancelSubscription(planId: number, id: number, storeId: number) {
    const session = JSON.parse(localStorage.getItem("session") || "{}");

    let storeIdToUse = 0;

    if (session.entityType === "Administrator") {
      storeIdToUse = storeId;
    } else {
      storeIdToUse = storeId;
    }

    return axios.delete(
      `${process.env.REACT_APP_API_BASE_URL}/stores/${storeIdToUse}/plans/${planId}/subscriptions/${id}`,
      {
        headers: {
          Authorization: `Bearer ${session.token}`,
        },
      }
    );
  }

  async getDevelopers() {
    const session = JSON.parse(localStorage.getItem("session") || "{}");

    if (session.entityType === "Administrator") {
      return axios.get(
        `${process.env.REACT_APP_API_BASE_URL}/admin/developers`,
        {
          headers: {
            Authorization: `Bearer ${session.token}`,
          },
        }
      );
    } else {
      return axios.get(
        `${process.env.REACT_APP_API_BASE_URL}/businesses/${session.businessId}/developers`,
        {
          headers: {
            Authorization: `Bearer ${session.token}`,
          },
        }
      );
    }
  }

  async getBoletoInstallments(orderNumber: string, storeId: number) {
    const session = JSON.parse(localStorage.getItem("session") || "{}");
    return axios.get(
      `${process.env.REACT_APP_API_BASE_URL}/stores/${storeId}/orders/${orderNumber}/boleto_receivables`,
      {
        headers: {
          Authorization: `Bearer ${session.token}`,
        },
      }
    );
  }

  async getResearchedTransaction({ type, value }: ISearch) {
    const session = JSON.parse(localStorage.getItem("session") || "{}");
    let query = "";

    query = `${type}=${value}`;

    if (session.entityType === "Business") {
      return await axios.get(
        `${process.env.REACT_APP_API_BASE_URL}/business/${session.businessId}/orders/search?${query}`,
        {
          headers: {
            Authorization: `Bearer ${session.token}`,
          },
        }
      );
    } else {
      return await axios.get(
        `${process.env.REACT_APP_API_BASE_URL}/stores/${session.storeId}/orders/search?${query}`,
        {
          headers: {
            Authorization: `Bearer ${session.token}`,
          },
        }
      );
    }
  }

  async exportOrders(startDate: string, endDate: string) {
    const session = JSON.parse(localStorage.getItem("session") || "{}");
    let query = "";

    return await axios.get(
      `${process.env.REACT_APP_API_BASE_URL}/stores/orders/export?businessid=${session.businessId}&&startDate=${startDate}&endDate=${endDate}`,
      {
        onDownloadProgress: (progressEvent: any) => {
          const total = parseFloat(
            progressEvent.currentTarget.responseHeaders["Content-Length"]
          );
          const current = progressEvent.currentTarget.response.length;

          let percentCompleted = Math.floor((current / total) * 100);
        },
      }
    );
  }

  async verifyPixPayment(orderNumber: string) {
    const session = JSON.parse(localStorage.getItem("session") || "{}");

    return axios.put(
      `${process.env.REACT_APP_API_BASE_URL}/pix/events/${orderNumber}`,
      {},
      {
        headers: {
          Authorization: `Bearer ${session.token}`,
        },
      }
    );
  }

  async getOrderNumberReceivables(orderNumber: string, storeId: number) {
    const session = JSON.parse(localStorage.getItem("session") || "{}");

    return axios.get(
      `${process.env.REACT_APP_API_BASE_URL}/stores/${storeId}/orders/${orderNumber}/receivables`,
      {
        headers: {
          Authorization: `Bearer ${session.token}`,
        },
      }
    );
  }

  async captureAnOrder(amount: number, orderNumber: string) {
    const session = JSON.parse(localStorage.getItem("session") || "{}");

    return axios.post(
      `${process.env.REACT_APP_API_BASE_URL}/stores/${session.storeId}/orders/${orderNumber}/capture`,
      {
        amount: amount,
      },
      {
        headers: {
          Authorization: `Bearer ${session.token}`,
        },
      }
    );
  }

  async cancelBoleto(storeId: number, orderNumber: string, boletoId: number) {
    const session = JSON.parse(localStorage.getItem("session") || "{}");

    return axios.post(
      `${process.env.REACT_APP_API_BASE_URL}/stores/${storeId}/orders/${orderNumber}/boletos/cancel/${boletoId}`,
      {},
      {
        headers: {
          Authorization: `Bearer ${session.token}`,
        },
      }
    );
  }

  async cancelBoletoOrder(storeId: number, orderNumber: string) {
    const session = JSON.parse(localStorage.getItem("session") || "{}");

    return axios.post(
      `${process.env.REACT_APP_API_BASE_URL}/stores/${storeId}/orders/${orderNumber}/boletos/cancel`,
      {},
      {
        headers: {
          Authorization: `Bearer ${session.token}`,
        },
      }
    );
  }
}

export default new OrderService();
