import React, { useState, useEffect } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import Skeleton from "react-loading-skeleton";
import { useHistory } from "react-router-dom";
import axios from "axios";
import { UnfilledButtonIconMask } from '../../components/Layout/Index';
import sheetsIcon from "assets/images/icon-sheets.svg";

//Hooks
import { useIntegrations } from "./hooks";
import { useValidateSession } from "../../hooks/useValidateSession";

//Types
import { Developer, Store, Webhook } from "./types";

//Components
import Select from "components/Forms/Select";
import { LayoutWrapper, ContentBox } from "components/Layout/Index";
import Buttons from "components/Forms/Buttons";
import Input from "components/Forms/InputFloatLabel";
import ExternalServiceConnection from "components/ExternalServiceConnection";
import WebhookConnectionForm from "components/WebhookConnectionForm";
import SelectStoresButtonModal from "./components/SelectStoresModal";
import SelectConnectedStoresButtonModal from "./components/SelectConnectedStoresModal";
import ExternalServiceConnectionWithModal from "components/ExternalServiceConnectionWithModal";
import SimpleCheckboxItem from "components/SimpleCheckboxItem";

import ErrorMessage from "./components/ErrorMessage";

//Services
import OrderService from "services/Order";
import SessionService from "services/Session";
import StoreService from "services/Store";
import UserProfileService from "services/UserProfile";
import WebhooksServices from "services/Webhooks";

//Icons
import plugaIcon from "assets/images/integrations/pluga.svg";
import zapierIcon from "assets/images/integrations/zapier.svg";
import iftttIcon from "assets/images/integrations/ifttt.svg";
import memberkitIcon from "assets/images/integrations/memberkit.svg";
import nuvemshopIcon from "assets/images/integrations/nuvemshop.svg";
import eNotasIcon from "assets/images/global/enotas.svg";
import infoIcon from "assets/images/bottom/financial-info.svg";

//Styles
import * as indexStyled from "../../styles/index-styles";
import * as detailsStyled from "../../components/Details/details-styles";
import * as styled from "../styles/developers-styles";

export default function Integrations() {
  useValidateSession();

  const [iDForStoreModal, setIDForStoreModal] = useState("");

  const {
    control,
    Controller,
    isViewingAsBusiness,
    isNuvemshopConnected,
    handleOpenNewPageOnConnect,
    handleNuvemshopMaxInstallments,
    loadingSaveOnHeader,
    successMessage,
    errorMessage,
    nuvemShopConnectedStores,
    nuvemshopPaymentMethods,
    setNuvemshopPaymentMethods,
    handleNuvemshopPaymentMethods,
    emptyNuvemshopPaymentMethods
  } = useIntegrations({ iDForStoreModal });

  const intl = useIntl();
  const history = useHistory();

  const [developers, setDevelopers] = useState<Developer[]>();
  const [webhooksList, setWebhookList] = useState<Webhook[]>([]);
  const [edittedWebhook, setEdittedWebhook] = useState<Webhook>({
    id: 0,
    endpoint: "",
    description: "",
    storeId: 0,
    destination: 0,
    events: [],
  });
  const [stores, setStores] = useState<Store[]>();
  const [isLoaded, setIsLoaded] = useState<boolean>(false);
  const [isLoadedStores, setIsLoadedStores] = useState<boolean>(false);
  const [inputType, setInputType] = useState("password");
  const [editWebhook, setEditWebhook] = useState(false);
  const [isLoadedWebhook, setIsLoadedWebhook] = useState(false);
  const [visibleEditWebhookForm, setVisibleEditWebhookForm] = useState(false);
  const [visibleWebhookId, setVisibleWebhookId] = useState(0);
  const [finishedTheEdition, setFinishedTheEdition] = useState(false);

  const [isEditWebhookProcessing, setIsEditWebhookProcessing] = useState(false);

  const [openAddWebhookForm, setOpenAddWebhookForm] = useState(false);

  const [visiblePass, setVisiblePass] = useState(false);
  const [visibleDeveloperId, setVisibleDeveloperId] = useState("");

  const [progressStep, setProgressStep] = useState(0);

  //enotas
  const [enotasKey, setEnotasKey] = useState("");
  const [enotasBusinessId, setEnotasBusinessId] = useState("");

  const [everyEnotas, setEveryEnotas] = useState(false);

  useEffect(() => {
    const session = JSON.parse(localStorage.getItem("session") || "{}");

    if(session.entityType === "Business") {
      OrderService.getDevelopers().then((response) => {
        let developersArray: Developer[] = [];
  
        const { businessName } = JSON.parse(
          localStorage.getItem("@PrimefySidebarInfos") || "{}"
        );
  
        if (response.data.stores.users) {
          response.data.stores.map((store: any) => {
            store.users.map((user: any) => {
              developersArray.push({
                username: user.username,
                password: user.password,
                storeId: store.id,
                storeName: store.name,
              });
            });
          });
        }
  
        if (response.data.users) {
          response.data.users.map((user: any) => {
            developersArray.push({
              username: user.username,
              password: user.password,
              storeId: response.data.businessId,
              storeName: businessName,
            });
          });
        }
  
        setDevelopers(developersArray);
        setStores(response.data.stores);
        setIsLoaded(true);
        setIsLoadedStores(true);
      });
    }


    StoreService.getStores()
      .then((response) => {
        let everyHaveGenerateInvoice = response.data.every((store: any) => {
          return store.generateInvoice;
        });

        setEveryEnotas(everyHaveGenerateInvoice);
      })
      .catch((err) => {
        console.log(err);
      });

    WebhooksServices.listAllWebhooks()
      .then((response) => {
        setWebhookList(response.data);
        setIsLoadedWebhook(true);
      })
      .catch((err) => {
        console.log(err);
        setIsLoadedWebhook(true);
      });
  }, []);

  useEffect(() => {
    if (finishedTheEdition) {
      WebhooksServices.listAllWebhooks()
        .then((response) => {
          setWebhookList(response.data);
          setIsLoadedWebhook(true);
        })
        .catch((err) => {
          console.log(err);
          setIsLoadedWebhook(true);
        });
    }
  }, [finishedTheEdition]);

  useEffect(() => {
    OrderService.getDistinctRecentOrders({ pageSize: 1 }).then((response) => {
      if (response.data.totalNumber > 0) {
        setProgressStep(3);
      } else {
        setProgressStep(2);
      }
    });
  }, []);

  function handleButton() {
    window.open("https://docs.premepay.com/", "_blank");
  }

  function toggleVisiblePass(id: string) {
    if (visiblePass && visibleDeveloperId !== id) {
      setVisibleDeveloperId(id);
    }

    if (visiblePass && visibleDeveloperId === id) {
      setVisiblePass(false);
      setVisibleDeveloperId("");
    } else {
      setVisiblePass(true);
      setVisibleDeveloperId(id);
    }
  }

  function handleConnectionEnotas() {
    UserProfileService.enotasConnection(enotasBusinessId, enotasKey)
      .then((response) => {})
      .catch((err) => {
        console.log(err);
      });
  }

  function toggleEditWebhook(id: number, webhook: Webhook) {
    setFinishedTheEdition(false);

    if (visibleEditWebhookForm && visibleWebhookId === id) {
      setVisibleEditWebhookForm(false);
      setVisibleWebhookId(0);
    } else {
      setVisibleEditWebhookForm(true);
      setVisibleWebhookId(id);

      setEdittedWebhook({
        id: webhook.id,
        endpoint: webhook.endpoint,
        description: webhook.description,
        storeId: webhook.storeId,
        destination: webhook.destination,
        events: [...webhook.events],
      });
    }
  }

  const [ isCreatedToken, setIsCreatedToken ] = useState(false)
  const [ createdToken, setCreatedToken ] = useState('')
  const session = JSON.parse(localStorage.getItem("session") || "{}");
  const isOwner = session.role === 'Store.Owner' || session.role === 'Business.Owner';

  console.log(createdToken, "scapadubirubá")

  function handleCreateToken() {
    const session = JSON.parse(localStorage.getItem("session") || "{}");
    console.log(session.storeId, "biribiribinhomnhonhomnhom");
    UserProfileService.getUserToken()
    .then(response => {
      setIsCreatedToken(true);
      setCreatedToken(response.data.token)
    })
    .catch(error => {
      console.error("Erro ao criar token:", error);
      setIsCreatedToken(false);
    });
 }

 const handleCopyToken = () => {
  navigator.clipboard.writeText(createdToken)
     .then(() => {
       alert('Token copiado para a área de transferência!');
     })
     .catch(err => {
       console.error('Erro ao copiar o token: ', err);
     });
 };
  
  return (
    <LayoutWrapper>
      <styled.RowWith2GridColumns>
        <div className="border-right complete-height">
          <ContentBox cssClass="border-bottom">
            <styled.IntegrationContainer>
              <styled.Header>
                <FormattedMessage id="developer.page.integration.title" />
              </styled.Header>

              <div className="integration-progress-container">
                <div className="integration-progress-header">
                  <styled.InfoIcon icon={infoIcon} />
                  <p>
                    {intl.formatMessage({
                      id:
                        progressStep === 3
                          ? "developer.page.integration.3.subtitle"
                          : "developer.page.integration.subtitle",
                    })}
                  </p>
                </div>

                <styled.ProgressBar3StepsContainer step={progressStep}>
                  <div className="background">
                    <div className="progress-1"></div>
                  </div>
                  <div className="background">
                    <div className="progress-2"></div>
                  </div>
                  <div className="background">
                    <div className="progress-3"></div>
                  </div>
                </styled.ProgressBar3StepsContainer>

                <p className="text">
                  {intl.formatMessage({
                    id: "developer.page.integration.text",
                  })}
                </p>
              </div>

              <div>
                <Buttons
                  mode="filled"
                  label={intl.formatMessage({
                    id: "developer.page.api.doc.button",
                  })}
                  handler={handleButton}
                />
              </div>
            </styled.IntegrationContainer>
          </ContentBox>

          {isViewingAsBusiness ? (
            <ContentBox>
              <styled.InternBoxWithAutoHeight>
                <styled.Header>
                  <FormattedMessage id="developer.page.list.stores" />
                </styled.Header>

                <indexStyled.TableList>
                  <indexStyled.TableListHeader>
                    <ul>
                      <li>
                        <FormattedMessage id="developer.page.list.store.name" />
                      </li>
                      <li>
                        <FormattedMessage id="developer.page.list.store.id" />
                      </li>
                    </ul>
                  </indexStyled.TableListHeader>
                  <indexStyled.TableItems className="width--80">
                    {(!stores || stores.length === 0) && isLoadedStores && (
                      <h3>{intl.formatMessage({ id: "items.not.found" })}</h3>
                    )}
                    {stores ? (
                      stores.map((stores) => (
                        <a key={stores.id}>
                          <ul>
                            <li>{stores.name}</li>
                            <li>{stores.id}</li>
                          </ul>
                        </a>
                      ))
                    ) : (
                      <Skeleton count={1} height={25} className="skeleton" />
                    )}
                  </indexStyled.TableItems>
                </indexStyled.TableList>
              </styled.InternBoxWithAutoHeight>
            </ContentBox>
          ) : null}
          { isOwner ?          
            (
            <ContentBox>
              <styled.InternBoxWithAutoHeight>
                <styled.Header>
                  <FormattedMessage id="developer.page.token.title" />
                </styled.Header>
                <p style={{marginTop: -25, marginBottom: 25}}>{intl.formatMessage({ id: "developer.page.token.description" })}</p>

                {!isCreatedToken && (
                  <Buttons
                      mode="unfilled"
                      label={intl.formatMessage({ id: "developer.page.token.create.button" })}
                      handler={handleCreateToken}
                  />
                  )}

                {/* <Buttons
                  mode="unfilled-with-icon"
                  icon={sheetsIcon}
                  label={intl.formatMessage({ id: "developer.page.token.create.button" })}
                  handler={handleCreateToken}
                /> */}
                {isCreatedToken && (
                <div
                  style={{
                    display: "flex",
                    flexDirection: 'row',
                    alignItems: 'center',
                    gap: 21
                  }}
                >

              <p>Token criado</p>
              <button
                style={{
                  border: '1px solid #E1E3E6',
                  borderRadius: 4,
                  padding: 8,
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  gap: 8
                }}
                onClick={handleCopyToken}
                >
                <img src={sheetsIcon} alt="folhas" />
                <p
                  style={{
                    fontSize: 14,
                    fontWeight: 400,
                  }}
                  >
                  Copiar
                  </p>
              </button>               
                  </div>
                )}
              </styled.InternBoxWithAutoHeight>
            </ContentBox>
          ) : null}
        </div>
        <div>
          <ContentBox cssClass="border-bottom">
            <styled.InternBoxWithAutoHeight>
              <div className="right-internal-container">
                <styled.Header>
                  <FormattedMessage id="webhook.title" />

                  {openAddWebhookForm ? (
                    <Buttons
                      mode="unfilled"
                      label={intl.formatMessage({ id: "integrations.cancel" })}
                      handler={() => setOpenAddWebhookForm(false)}
                    />
                  ) : (
                    <Buttons
                      mode="unfilled"
                      label={intl.formatMessage({ id: "integrations.add.new" })}
                      handler={() => setOpenAddWebhookForm(true)}
                    />
                  )}
                </styled.Header>

                <p>{intl.formatMessage({ id: "webhook.description" })}</p>

                {!openAddWebhookForm &&
                webhooksList.length > 0 &&
                isLoadedWebhook ? (
                  <indexStyled.TableList>
                    <indexStyled.TableListHeader>
                      <div className="list-webhooks--header ">
                        <ul>
                          <li>
                            <FormattedMessage id="integrations.list.status" />
                          </li>
                          <li>
                            <FormattedMessage id="integrations.list.description" />
                          </li>
                          <li>
                            <FormattedMessage id="integrations.list.events" />
                          </li>
                          <li></li>
                        </ul>
                      </div>
                    </indexStyled.TableListHeader>

                    <indexStyled.TableItems>
                      <div className="list-webhooks">
                        {webhooksList.map((webhook: Webhook) => (
                          <>
                            <ul key={webhook.id}>
                              <li>
                                <span className="status-succeeded">
                                  {intl.formatMessage({ id: "status.active" })}
                                </span>
                              </li>
                              <li>{webhook.description}</li>
                              <li>
                                {webhook.events.length}{" "}
                                {intl.formatMessage({
                                  id: "settings.integrations.events",
                                })}
                              </li>
                              <li>
                                {visibleEditWebhookForm &&
                                visibleWebhookId === webhook.id ? (
                                  <Buttons
                                    mode="unfilled"
                                    label={intl.formatMessage({
                                      id: "integrations.cancel",
                                    })}
                                    handler={() =>
                                      setVisibleEditWebhookForm(false)
                                    }
                                  />
                                ) : (
                                  <Buttons
                                    mode="unfilled"
                                    label={intl.formatMessage({
                                      id: "settings.team.list.edit",
                                    })}
                                    handler={() =>
                                      toggleEditWebhook(webhook.id, webhook)
                                    }
                                  />
                                )}
                              </li>
                            </ul>

                            {visibleEditWebhookForm &&
                            visibleWebhookId === webhook.id ? (
                              <div className="height--550-user-edit-form">
                                <indexStyled.EditUserContainer>
                                  <detailsStyled.DetailsItems>
                                    <WebhookConnectionForm
                                      destination={webhook.destination}
                                      isEditting={true}
                                      webhookToEdit={webhook}
                                      setVisibleEditWebhookForm={
                                        setVisibleEditWebhookForm
                                      }
                                      setFinishedTheEdition={
                                        setFinishedTheEdition
                                      }
                                    />
                                  </detailsStyled.DetailsItems>
                                </indexStyled.EditUserContainer>
                              </div>
                            ) : null}
                          </>
                        ))}
                      </div>
                    </indexStyled.TableItems>
                  </indexStyled.TableList>
                ) : null}

                {openAddWebhookForm && isLoadedWebhook ? (
                  <detailsStyled.DetailsItems>
                    <WebhookConnectionForm
                      destination={0}
                      setFinishedTheEdition={setFinishedTheEdition}
                    />
                  </detailsStyled.DetailsItems>
                ) : null}
              </div>
            </styled.InternBoxWithAutoHeight>
          </ContentBox>

          <ContentBox>
            <styled.InternBoxWithAutoHeight>
              <div className="right-internal-container">
                <styled.Header className="column-header">
                  <FormattedMessage id="integrations.title" />
                </styled.Header>
                <p>{intl.formatMessage({ id: "integrations.description" })}</p>

                <detailsStyled.DetailsItems>
                  <ExternalServiceConnectionWithModal
                    icon={memberkitIcon}
                    description="settings.memberkit.description"
                    withSeparator={true}
                    setFinishedTheEdition={setFinishedTheEdition}
                  ></ExternalServiceConnectionWithModal>

                  <ExternalServiceConnection
                    icon={eNotasIcon}
                    description="settings.integrations.e.notas"
                    isConnected={everyEnotas}
                    withSeparator={true}
                    setFinishedTheEdition={setFinishedTheEdition}
                  >
                    <div className="input-item margin-top-24">
                      <h5>
                        {intl.formatMessage({
                          id: "settings.business.api.key.enotas",
                        })}
                      </h5>

                      <Input
                        required={true}
                        mode="normal"
                        name="enotasKey"
                        value={enotasKey}
                        placeholder=""
                        handler={setEnotasKey}
                      />
                    </div>

                    <div className="input-item margin-top-24">
                      <h5>
                        {intl.formatMessage({
                          id: "settings.business.business.number",
                        })}
                      </h5>
                      <SelectStoresButtonModal
                        handler={setEnotasBusinessId}
                        setID={setIDForStoreModal}
                        labelWithStoreNameAndId={false}
                      />
                    </div>

                    <div className="margin-top-24 margin-bottom-24">
                      <Buttons
                        mode="filled"
                        label={intl.formatMessage({ id: "integrations.add" })}
                        handler={handleConnectionEnotas}
                      />
                    </div>
                  </ExternalServiceConnection>

                  <ExternalServiceConnection
                    icon={plugaIcon}
                    description="webhook.connection.pluga"
                    withSeparator={true}
                    setFinishedTheEdition={setFinishedTheEdition}
                  >
                    <WebhookConnectionForm
                      destination={1}
                      setFinishedTheEdition={setFinishedTheEdition}
                    />
                  </ExternalServiceConnection>

                  <ExternalServiceConnection
                    icon={zapierIcon}
                    description="webhook.connection.zapier"
                    withSeparator={true}
                    setFinishedTheEdition={setFinishedTheEdition}
                  >
                    <WebhookConnectionForm
                      destination={2}
                      setFinishedTheEdition={setFinishedTheEdition}
                    />
                  </ExternalServiceConnection>

                  <ExternalServiceConnection
                    icon={iftttIcon}
                    description="webhook.connection.ifttt"
                    setFinishedTheEdition={setFinishedTheEdition}
                  >
                    <WebhookConnectionForm
                      destination={3}
                      setFinishedTheEdition={setFinishedTheEdition}
                    />
                  </ExternalServiceConnection>

                  <ExternalServiceConnection
                    icon={nuvemshopIcon}
                    description="webhook.connection.nuvemshop"
                    setFinishedTheEdition={setFinishedTheEdition}
                    isConnected={isNuvemshopConnected}
                    conditionalOpenChildren={false}
                    useSaveButtonOnHeader={true}
                    handleSaveOnHeader={handleNuvemshopMaxInstallments}
                    disable={!isNuvemshopConnected}
                    loadingSaveOnHeader={loadingSaveOnHeader}
                    disableSave={(iDForStoreModal === "" && isViewingAsBusiness) || emptyNuvemshopPaymentMethods}
                  >
                    {isViewingAsBusiness ? (
                      <div className="input-item content-container">
                        <h5>
                          {intl.formatMessage({
                            id: "webhook.connection.nuvemshop.store",
                          })}
                        </h5>

                        <SelectConnectedStoresButtonModal
                          handler={setEnotasBusinessId}
                          setID={setIDForStoreModal}
                          labelWithStoreNameAndId={true}
                          connectedStores={nuvemShopConnectedStores}
                        />
                      </div>
                    ) : null}
                    
                    {(isViewingAsBusiness && iDForStoreModal !== "") || !isViewingAsBusiness ? 
                      <>
                        <SimpleCheckboxItem
                          valueToCheckt={nuvemshopPaymentMethods.ccard}
                          placeholder="webhook.connection.nuvemshop.ccard"
                          tipText=""
                          toggle={() => handleNuvemshopPaymentMethods("ccard")}
                          name="apply-smart-installments"
                          value="apply-smart-installments"
                        >
                          <div className="input-item content-container intern-form-container">
                            <h5>
                              {intl.formatMessage({
                                id: "webhook.connection.nuvemshop.installments",
                              })}{" "}
                              <img src={infoIcon} alt="info" />
                            </h5>

                            <Controller
                              name="nuvemshop.maxCharges"
                              control={control}
                              defaultValue="12"
                              render={({ field }) => (
                                <Select
                                  mode="normal-child"
                                  placeholder=""
                                  handler={() => {}}
                                  {...field}
                                >
                                  {Array.from({ length: 12 }).map((_, index) => (
                                    <option value={index + 1}>
                                      {index + 1}x
                                    </option>
                                  ))}
                                </Select>
                              )}
                            />                 
                          </div>
                        
                        </ SimpleCheckboxItem>
                        
                        <SimpleCheckboxItem
                          valueToCheckt={nuvemshopPaymentMethods.boleto}
                          placeholder="webhook.connection.nuvemshop.boleto"
                          tipText=""
                          toggle={() => handleNuvemshopPaymentMethods("boleto")}
                          name="apply-smart-installments"
                          value="apply-smart-installments"
                        />
                        
                        <SimpleCheckboxItem
                          valueToCheckt={nuvemshopPaymentMethods.pix}
                          placeholder="webhook.connection.nuvemshop.pix"
                          tipText=""
                          toggle={() => handleNuvemshopPaymentMethods("pix")}
                          name="apply-smart-installments"
                          value="apply-smart-installments"
                        />
                      </>
                     : null}
                    
                    <div className="input-item content-container">
                      {successMessage !== "" && (
                        <span className="success">
                          {intl.formatMessage({ id: successMessage })}
                        </span>
                      )}
                      {(errorMessage !== "" || (emptyNuvemshopPaymentMethods && iDForStoreModal !== "")) && (
                        <span className="error">
                          {intl.formatMessage({ id: errorMessage })}
                        </span>
                      )}
                    </div>
                    
                  </ExternalServiceConnection>
                </detailsStyled.DetailsItems>
              </div>
            </styled.InternBoxWithAutoHeight>
          </ContentBox>
        </div>
      </styled.RowWith2GridColumns>
    </LayoutWrapper>
  );
}
