import axios from "axios";

class SessionService {
  async activateBankAccount(bankAccountIdToActivate: string | null) {
    const session = JSON.parse(localStorage.getItem("session") || "{}");
    return axios.put(
      `${process.env.REACT_APP_API_BASE_URL}/stores/${session.storeId}/bank_accounts`,
      {
        bankAccountId: bankAccountIdToActivate,
      },
      {
        headers: {
          Authorization: `Bearer ${session.token}`,
        },
      }
    );
  }

  async getBalances(id: number) {
    const session = JSON.parse(localStorage.getItem("session") || "{}");

    if (id === 0) {
      if (session.entityType === "Business") {
        return axios.get(
          `${process.env.REACT_APP_API_BASE_URL}/businesses/${session.businessId}/balances`,
          {
            headers: {
              Authorization: `Bearer ${session.token}`,
            },
          }
        );
      } else {
        return axios.get(
          `${process.env.REACT_APP_API_BASE_URL}/stores/${session.storeId}/balances`,
          {
            headers: {
              Authorization: `Bearer ${session.token}`,
            },
          }
        );
      }
    } else {
      return axios.get(
        `${process.env.REACT_APP_API_BASE_URL}/stores/${id}/balances`,
        {
          headers: {
            Authorization: `Bearer ${session.token}`,
          },
        }
      );
    }
  }

  async getBalancesWithForeignAmount() {
    const session = JSON.parse(localStorage.getItem("session") || "{}");
    return axios.get(
      `${process.env.REACT_APP_API_BASE_URL}/stores/${session.storeId}/fx/balance`,
      {
        headers: {
          Authorization: `Bearer ${session.token}`,
        },
      }
    );
  }

  async getDealType(token: string, storeId: number) {
    return axios.get(
      `${process.env.REACT_APP_API_BASE_URL}/stores/${storeId}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
  }

  async getAvailableAmountToWithdraw(amount: number) {
    const session = JSON.parse(localStorage.getItem("session") || "{}");
    return axios.get(
      `${process.env.REACT_APP_API_BASE_URL}/stores/${session.storeId}/fx/preview_withdrawal?amount=${amount}`,
      {
        headers: {
          Authorization: `Bearer ${session.token}`,
        },
      }
    );
  }

  async get() {
    const session = JSON.parse(localStorage.getItem("session") || "{}");
    return axios.get(
      `${process.env.REACT_APP_API_BASE_URL}/stores/${session.storeId}`,
      {
        headers: {
          Authorization: `Bearer ${session.token}`,
        },
      }
    );
  }

  async getAdminBankAccountId() {
    const session = JSON.parse(localStorage.getItem("session") || "{}");
    return axios.get(`${process.env.REACT_APP_API_BASE_URL}/stores/${80}`, {
      headers: {
        Authorization: `Bearer ${session.token}`,
      },
    });
  }

  async getInfos() {
    const session = JSON.parse(localStorage.getItem("session") || "{}");
    const storeIdSession = JSON.parse(
      localStorage.getItem("storeIdSession") || "{}"
    );

    if (session.entityType === "Administrator") {
      return axios.get(`${process.env.REACT_APP_API_BASE_URL}/admin/stores`, {
        headers: {
          Authorization: `Bearer ${session.token}`,
        },
      });
    } else {
      if (storeIdSession.storeIdSession > 0 && session.businessId > 0) {
        return axios.get(
          `${process.env.REACT_APP_API_BASE_URL}/businesses/${session.businessId}`,
          {
            headers: {
              Authorization: `Bearer ${session.token}`,
            },
          }
        );
      } else {
        return axios.get(
          `${process.env.REACT_APP_API_BASE_URL}/stores/${session.storeId}`,
          {
            headers: {
              Authorization: `Bearer ${session.token}`,
            },
          }
        );
      }
    }
  }
  async getStoreByID() {
    const session = JSON.parse(localStorage.getItem("session") || "{}");
    
    return axios.get(
      `${process.env.REACT_APP_API_BASE_URL}/stores/${session.storeId}`,
      {
        headers: {
          Authorization: `Bearer ${session.token}`,
        },
      }
    );
  }

  async getStores() {
    const session = JSON.parse(localStorage.getItem("session") || "{}");

    if (session.entityType === "Administrator") {
      return axios.get(`${process.env.REACT_APP_API_BASE_URL}/admin/stores`, {
        headers: {
          Authorization: `Bearer ${session.token}`,
        },
      });
    } else {
      return axios.get(`${process.env.REACT_APP_API_BASE_URL}/stores`, {
        headers: {
          Authorization: `Bearer ${session.token}`,
        },
      });
    }
  }

  async getStoresToList() {
    const session = JSON.parse(localStorage.getItem("session") || "{}");
    return axios.get(
      `${process.env.REACT_APP_API_BASE_URL}/businesses/${session.businessId}/stores`,
      {
        headers: {
          Authorization: `Bearer ${session.token}`,
        },
      }
    );
  }

  isValid() {
    const session = JSON.parse(localStorage.getItem("session") || "{}");
    return new Date(session.expiryAt) > new Date();
  }

  clean() {
    localStorage.removeItem("session");
  }
}

export default new SessionService();
