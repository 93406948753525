import axios from 'axios';

class ManagerLinks {
    
  async getLinks() {
    const session = JSON.parse(localStorage.getItem("session") || "{}");

    return axios.get(`${process.env.REACT_APP_API_BASE_URL}/stores/${session.storeId}/payment_links`, {
      headers: {
        'Authorization': `Bearer ${session.token}`
      }
    });
  }

  async getLinkDetails(url: string) {
    const session = JSON.parse(localStorage.getItem("session") || "{}");

    return axios.get(`${process.env.REACT_APP_API_BASE_URL}/payment_links/${url}/orders`, {
      headers: {
        'Authorization': `Bearer ${session.token}`
      }
    });
  }

  async deleteLink(linkId: number) {
    const session = JSON.parse(localStorage.getItem("session") || "{}");

    return axios.delete(`${process.env.REACT_APP_API_BASE_URL}/stores/${session.storeId}/payment_links/${linkId}`, {
      headers: {
        'Authorization': `Bearer ${session.token}`
      }
    });
  }

  async changePAymentLinkStatus(url: string) {
    const session = JSON.parse(localStorage.getItem("session") || "{}");
    
    return axios.put(`${process.env.REACT_APP_API_BASE_URL}/payment_links/${url}`, 
    {},
    {
        headers: {
            'Authorization': `Bearer ${session.token}`,
            'Content-Type': "application/json"
        }
    });
  }
}

export default new ManagerLinks();
