import React from "react";
import { useHistory } from "react-router-dom";

//Componets
import { LayoutWrapper } from "components/Layout/Index";
import SettingsDetails from "components/Details/SettingsDetails";

//Services
import SessionService from "services/Session";

//Styles
import * as indexStyled from "../styles/index-styles";

function Settings() {
  let history = useHistory();

  if (!SessionService.isValid()) {
    SessionService.clean();

    history.push("/login");
  }

  return (
    <>
      <LayoutWrapper>
        <indexStyled.RowList className=" transactions_details">
          <SettingsDetails />
        </indexStyled.RowList>
      </LayoutWrapper>
    </>
  );
}

export default Settings;
