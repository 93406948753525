import React, { useEffect, useState, useContext } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";

import { ToggleTemaContext } from "../../providers/toggleTheme";

import { useIntl } from "react-intl";
import { ThemeContext } from "styled-components";

//Styles
import * as styled from "./sidebar-mobile-styles";

// Store
import { useSettings } from "../../store/settings";

//Assets
import homeIcon from "assets/images/sidebar/home.svg";
import homeActiveIcon from "assets/images/sidebar/home _active2.svg";
import salesIcon from "assets/images/sidebar/money.svg";
import salesActiveIcon from "assets/images/sidebar/money_active.svg";
import reportIcon from "assets/images/sidebar/report.svg";
import reportActiveIcon from "assets/images/sidebar/report_active.svg";
import developerstIcon from "assets/images/sidebar/book.svg";
import developerstActiveIcon from "assets/images/sidebar/book_active.svg";
import settingstIcon from "assets/images/sidebar/settings.svg";
import settingstActiveIcon from "assets/images/sidebar/settings_active.svg";
import searchIcon from "assets/images/sidebar/search.svg";
import searchActiveIcon from "assets/images/sidebar/search_active.svg";

const Index = (props: any) => {
  const { toggleTheme } = useContext(ToggleTemaContext);

  const [isProd, setIsProd] = useState(false);
  const [isProcessingStore, setIsProcessingStore] = useState(false);
  const [role, setRole] = useState<boolean>(false);
  const [hasCommissioning, setHasCommissioning] = useState<boolean>(false);
  const [accessLevel, setAccessLevel] = useState<number | undefined>();
  const [isAdmin, setIsAdmin] = useState(false);
  const [modeText, setModeText] = useState("escuro");

  const location = useLocation();
  const history = useHistory();

  const { title } = useContext(ThemeContext);

  const { environment, status } = useSettings((state) => state.settings);

  useEffect(() => {
    const session = JSON.parse(localStorage.getItem("session") || "{}");

    setIsProd(environment === "prod" ? true : false);

    if (status !== "enabled" && status !== "denied") {
      setIsProcessingStore(true);
    } else {
      setIsProcessingStore(false);
    }

    if (
      [
        "Business.Owner",
        "Business.API",
        "Business.Administrator",
        "Administrator.Owner",
      ].includes(session.role)
    ) {
      setRole(true);
    } else {
      setRole(false);
    }

    switch (session.role) {
      case "Business.Owner":
      case "Store.Owner":
      case "Administrator.Owner":
        setAccessLevel(0);
        break;
      case "Business.Administrator":
      case "Store.Administrator":
        setAccessLevel(1);
        break;
      case "Business.Agent":
      case "Store.Agent":
        setAccessLevel(2);
        break;
      case "Business.ViewOnly":
      case "Store.ViewOnly":
        setAccessLevel(3);
        break;
      case "Business.API":
      case "Store.API":
        setAccessLevel(4);
        break;
      default:
        setAccessLevel(undefined);
    }

    /admin/.test(location.pathname) ? setIsAdmin(true) : setIsAdmin(false);

    setHasCommissioning(session.hasCommissioning);
  }, [location.pathname]);

  let intl = useIntl();

  const [activeScreen, setActiveScreen] = useState({
    home: ["/", "login", "/admin"].includes(location.pathname) ? true : false,
    transactions: ["transactions"].includes(location.pathname) ? true : false,
    commissions: ["commissions"].includes(location.pathname) ? true : false,
    reports: ["reports"].includes(location.pathname) ? true : false,
    developers: ["developers"].includes(location.pathname) ? true : false,
    settings: ["settings"].includes(location.pathname) ? true : false,
    search: ["search"].includes(location.pathname) ? true : false,
  });

  useEffect(() => {
    title === "light" ? setModeText("claro") : setModeText("escuro");
  }, [title]);

  useEffect(() => {
    setActiveScreen({
      home: ["/", "login", "/admin"].includes(location.pathname) ? true : false,
      transactions: ["/transactions", "/admin/transactions"].includes(
        location.pathname
      )
        ? true
        : false,
      commissions: ["/sale-commissions", "/admin/sale-commissions"].includes(
        location.pathname
      )
        ? true
        : false,
      reports: ["/reports", "/admin/reports"].includes(location.pathname)
        ? true
        : false,
      developers: ["/integrations", "/admin/integrations"].includes(
        location.pathname
      )
        ? true
        : false,
      settings: ["/settings", "/admin/settings"].includes(location.pathname)
        ? true
        : false,
      search: ["/search", "/admin/search"].includes(location.pathname)
        ? true
        : false,
    });
  }, [location.pathname]);

  function handleEnvironment() {
    const session = JSON.parse(localStorage.getItem("session") || "{}");

    window.location.href = `https://${
      isProd ? "test." : ""
    }dashboard.premepay.com${location.pathname}?access_token=${session.token}`;
  }

  return ["/login", "/admin/login", "/new-password", "/signup"].includes(
    location.pathname
  ) ? (
    <span></span>
  ) : (
    <styled.Sidebar className="border-top">
      <styled.MenuItems>
        <Link
          to={isAdmin ? "/admin" : "/"}
          className={`menu-nav_item ${activeScreen.home ? "active" : ""}`}
        >
          <img src={activeScreen.home ? homeActiveIcon : homeIcon} alt="home" />
          <div className="label">
            {intl.formatMessage({ id: "button.sidebar.home" })}
          </div>
        </Link>

        <Link
          to={isAdmin ? "/admin/transactions" : "/transactions"}
          className={`menu-nav_item ${
            activeScreen.transactions ? "active" : ""
          }`}
        >
          <img
            src={activeScreen.transactions ? salesActiveIcon : salesIcon}
            alt="sales"
          />
          <div className="label">
            {intl.formatMessage({ id: "button.sidebar.transactions" })}
          </div>
        </Link>

        <Link
          to={isAdmin ? "/admin/reports" : "/reports"}
          className={`menu-nav_item ${activeScreen.reports ? "active" : ""}`}
        >
          <img
            src={activeScreen.reports ? reportActiveIcon : reportIcon}
            alt="reports"
          />
          <div className="label">Relatórios</div>
        </Link>

        {accessLevel !== undefined && (accessLevel === 0 || accessLevel === 1) && (
          <Link
            ref={props.settingsRef}
            to={isAdmin ? "/admin/settings" : "/settings"}
            className={`menu-nav_item ${activeScreen.settings ? "active" : ""}`}
          >
            <span>
              <img
                src={
                  activeScreen.settings ? settingstActiveIcon : settingstIcon
                }
                alt="settings"
              />
              <div className="label">
                {intl.formatMessage({ id: "button.sidebar.settings" })}
              </div>
            </span>
          </Link>
        )}

        {/* <Link to={isAdmin ? "/admin/search" : "/search"} 
        className={`menu-nav_item ${activeScreen.search ? 'active' : ''}`}>
          <img src={activeScreen.search ? searchActiveIcon : searchIcon} alt="search" />
          <div className="label">Buscar</div>
      </Link> */}
      </styled.MenuItems>
    </styled.Sidebar>
  );
};

export default Index;
