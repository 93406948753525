import React from 'react';
import { useHistory } from 'react-router-dom';

//Services
import SessionService from 'services/Session';

//Components
import { LayoutWrapper } from 'components/Layout/Index';
import PaymentLinkBoxDetails from 'components/Details/PaymentLinkBoxDetails';

import * as indexStyled from '../styles/index-styles';

function PaymentLinkDetails() {
  let history = useHistory();

  if (!SessionService.isValid()) {
    SessionService.clean();

    history.push("/login");
  }

  return (
    <>
      <LayoutWrapper>
        <indexStyled.RowList className="transactions_details">
          <PaymentLinkBoxDetails />
        </indexStyled.RowList>
      </LayoutWrapper>
    </>
  )
}

export default PaymentLinkDetails;
