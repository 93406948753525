import { useState, useEffect, useRef } from "react";
import { useLocation, useHistory } from "react-router-dom";

//Services
import StoreService from "../../../../../services/Store";
import AdminService from "../../../../../services/Admin";
import WebhooksService from "../../../../../services/Webhooks";

// Store
import { useSettings } from "../../../../../store/settings";
import { useAuth } from "../../../../../store/auth";
import { useNuvemshop } from "../../../../../store/nuvemshop";

//Types
import { Store } from "../types";
import type { TourProps } from "antd";

//Hooks
import useClickOutside from "../../../../../hooks/useClickOutside";

export const useViewAsModal = () => {
  const location = useLocation();
  const history = useHistory();

  const viewasModalRef = useRef(null);

  const steps: TourProps["steps"] = [
    {
      title: "Selecione a loja que quer conectar com a Nuvemshop",
      description: "",
      placement: "right",
      target: () => viewasModalRef?.current,
    },
  ];

  let allParams = new URLSearchParams(location.search);
  let allFormattedParams = Object.fromEntries(allParams.entries());

  const [openViewAsDueToCode, setOpenViewAsDueToCode] = useState(false);
  const [openViewAsHighLight, setOpenViewAsHighLight] = useState(false);
  const [isMounted, setIsMounted] = useState(false);
  const [showRequestPaymentModal, setShowRequestPaymentModal] = useState(false);

  const { session, setSession } = useAuth();
  const { nuvemshop, resetStore } = useNuvemshop();

  const [isAdmin, setIsAdmin] = useState<boolean>();

  const [isAdminUser, setIsAdminUser] = useState<boolean>(() => {
    if (session.businessId === 0 && session.storeId === 0) return true;

    return false;
  });

  const [selectedRadioButton, setSelectedRadioButton] = useState<string>("");
  const [stores, setStores] = useState<Store[]>([]);
  const [businessId, setBusinessId] = useState(0);
  const [textButtonView, setTextButtonView] = useState("Visualizando todos");
  const [hasComissioningState, setHasComissioningState] = useState(false);
  const [calledFilter, setCalledFilter] = useState(false);
  const [wantedStore, setWantedStore] = useState("");
  const [backupStores, setBackupStores] = useState<Store[]>([]);
  const [businessName, setBusinessName] = useState(() => {
    const { businessName } = JSON.parse(
      localStorage.getItem("@PrimefySidebarInfos") || "{}"
    );

    return businessName;
  });

  const { status } = useSettings((state) => state.settings);

  useEffect(() => {
    getSessionInfos();
  }, []);

  useEffect(() => {
    if (backupStores.length > 0 && nuvemshop.code !== "") {
      setOpenViewAsDueToCode(true);

      setTimeout(() => {
        setOpenViewAsHighLight(true);
      }, 1000);
    }
  }, [backupStores]);

  async function getFilteredStoreForAdmin(filterValue: string) {
    let finalFilteredStores = [];
    let endpointArray = [];
    let filterString = "";

    const filterBy =
      filterValue.replaceAll(".", "").replace("-", "").replace("/", "")
        .length >= 11
        ? "identificationNumber"
        : parseInt(filterString) > 0
        ? "storeId"
        : "name";

    let cleanIdentificatioNumber = "";
    if (filterBy === "identificationNumber")
      cleanIdentificatioNumber = filterValue
        .replaceAll(".", "")
        .replace("-", "")
        .replace("/", "");

    let endpointObject = {
      name:
        filterBy === "name" ? `name=${filterValue.toLocaleLowerCase()}` : "",
      storeId: filterBy === "storeId" ? `storeId=${filterValue}` : "",
      identificationNumber:
        filterBy === "identificationNumber"
          ? `identificationNumber=${cleanIdentificatioNumber}`
          : "",
    };

    for (const [_, value] of Object.entries(endpointObject)) {
      if (value !== "") {
        endpointArray.push(value);
      }
    }

    filterString = endpointArray.join("&");

    AdminService.listAllStores(
      filterString !== "" ? `?${filterString}` : ""
    ).then((response) => {
      finalFilteredStores = response.data.stores.sort((a: Store, b: Store) =>
        a.socialName?.localeCompare(b?.socialName)
      );

      setCalledFilter(true);
      setStores(finalFilteredStores);
    });
  }

  useEffect(() => {
    if (wantedStore !== "") {
      const filteredStores = backupStores.filter((store: Store) =>
        store.socialName?.toLowerCase().includes(wantedStore.toLowerCase())
      );

      if (isAdminUser && filteredStores.length === 0) {
        getFilteredStoreForAdmin(wantedStore);
      } else {
        setStores(filteredStores);
      }
    }

    if (wantedStore === "") {
      setStores(backupStores);
    }
  }, [wantedStore]);

  function getSessionInfos() {
    const sessionString = localStorage.getItem("session");

    const sessionObject = JSON.parse(sessionString ? sessionString : "{}");

    setBusinessId(sessionObject.businessId);

    if (!isAdminUser) {
      StoreService.getStores().then((response) => {
        setBackupStores(
          response.data.sort((a: Store, b: Store) =>
            a.socialName.localeCompare(b.socialName)
          )
        );
        return setStores(
          response.data.sort((a: Store, b: Store) =>
            a.socialName.localeCompare(b.socialName)
          )
        );
      });
    } else {
      AdminService.listAllStores("").then((response) => {
        setBackupStores(
          response.data.stores.sort((a: Store, b: Store) =>
            a.socialName?.localeCompare(b?.socialName)
          )
        );
        return setStores(
          response.data.stores.sort((a: Store, b: Store) =>
            a.socialName?.localeCompare(b?.socialName)
          )
        );
      });
    }

    getNameStore();
  }

  const [haveStoreName, setHaveStoreName] = useState(false);

  function getNameStore() {
    const sessionString = localStorage.getItem("session");

    const sessionObject = JSON.parse(sessionString ? sessionString : "{}");

    if (sessionObject.nameStore !== undefined) {
      setHaveStoreName(true);
      setTextButtonView(sessionObject.nameStore);
    } else {
      setTextButtonView(businessName);
    }
  }

  function handleShowModal() {
    if (viewasModalRef.current) {
      setIsMounted(false);
      setShowRequestPaymentModal(true);
    } else {
      setIsMounted(true);
      setShowRequestPaymentModal(true);
    }
  }

  useClickOutside(viewasModalRef, isMounted, setIsMounted);

  function selectStore(
    e: React.FormEvent<HTMLDivElement>,
    selectedStore: number
  ) {
    e.preventDefault();

    if (nuvemshop.code !== "") {
      WebhooksService.createPaymentProvider(selectedStore, nuvemshop.code);
    }

    const sessionString = localStorage.getItem("session");

    const { token, expiryAt, entityType, businessId, storeId, role, name } =
      JSON.parse(sessionString ? sessionString : "{}");

    StoreService.getDealType(token, Number(selectedStore)).then((response) => {
      localStorage.removeItem("session");

      const nameStoreIndex = stores.findIndex(
        (store) => store.id === Number(selectedStore)
      );

      const nameStore = stores[nameStoreIndex].socialName;
      const businessIdSelected = isAdmin
        ? stores[nameStoreIndex].businessId
        : businessId;

      const sessionObject = {
        token,
        expiryAt,
        storeId: Number(selectedStore),
        entityType: isAdmin
          ? `${entityType}-Store`
          : entityType,
        businessId: businessIdSelected,
        nameStore,
        hasCommissioning:
          response.data.dealType === 0 || response.data.dealType === 2
            ? true
            : false,
        role,
        name,
      };

      console.log(sessionObject, isAdmin, "teste do adm");

      localStorage.setItem("session", JSON.stringify(sessionObject));

      setSession(sessionObject);

      if (nuvemshop.code !== "") history.push("/integrations");
      window.location.reload();
      resetStore();
    });
  }

  function clearRadioButton(e: React.FormEvent<HTMLButtonElement>) {
    e.preventDefault();

    const sessionString = localStorage.getItem("session");
    const storeIdString = localStorage.getItem("storeIdSession");
    const isAdminString = localStorage.getItem("isAdmin");

    const {
      token,
      expiryAt,
      entityType,
      businessId,
      storeId,
      role,
      name,
      hasComissioning,
    } = JSON.parse(sessionString ? sessionString : "{}");
    const { storeIdSession } = JSON.parse(storeIdString ? storeIdString : "{}");

    if (!isAdminUser) {
      localStorage.removeItem("session");

      StoreService.getDealType(token, storeIdSession).then((response) => {
        const sessionObject = {
          token,
          expiryAt,
          storeId: storeIdSession,
          entityType: "Business",
          businessId,
          hasCommissioning:
            response.data.dealType === 0 || response.data.dealType === 2
              ? true
              : false,
          role,
          name,
        };

        localStorage.setItem("session", JSON.stringify(sessionObject));

        setSession(sessionObject);
        window.location.reload();
      });
    } else {
      localStorage.removeItem("session");

      AdminService.listAllStoresLogin(token).then((response) => {
        const sessionObject = {
          token,
          expiryAt,
          storeId: 0,
          entityType: "Administrator",
          businessId: 0,
          hasCommissioning:
            response.data.dealType === 0 || response.data.dealType === 2
              ? true
              : false,
          role,
          name,
        };

        localStorage.setItem("session", JSON.stringify(sessionObject));

        setSession(sessionObject);
        window.location.reload();
      });
    }
  }

  return {
    handleShowModal,
    showRequestPaymentModal,
    textButtonView,
    isMounted,
    haveStoreName,
    setShowRequestPaymentModal,
    wantedStore,
    clearRadioButton,
    setWantedStore,
    stores,
    selectStore,
    status,
    viewasModalRef,
    steps,
    openViewAsHighLight,
    openViewAsDueToCode,
  };
};
