import React, { useState, useEffect, useRef } from "react";

import { FormattedMessage, useIntl } from "react-intl";
import { useHistory, useLocation } from "react-router-dom";
import Skeleton from "react-loading-skeleton";

//Services
import StoreService from "services/Store";
import UserProfileService from "services/UserProfile";

//Helpers
import brStates from "helpers/states-format";
import creditCardNetwork from "helpers/creditCardNetwork";

//Components
import SettingsNotificationButtonModal from "components/Layout/Modals/SettingsNotificationButtonModal";
import SelectStoresButtonModal from "components/Layout/Modals/SelectStoresButtonModal";
import Buttons from "../Forms/Buttons";
import Input from "components/Forms/InputFloatLabel";
import Select from "components/Forms/Select";
import NationsSelectButtonModal from "components/Layout/Modals/NationsSelectButtonModal";
import TipButtonModal from "components/Layout/Modals/TipButtonModal";
import FeeItem from "./FeeItem";
import SmallProcessingButton from "../Forms/SmallProcessingButton";
import { Button, UnfilledButtonWithoutIcon } from "../Layout/Index";

//Assets
import buttonEdit from "assets/images/button-edit.svg";
import eyeIcon from "assets/images/icon-eye.svg";
import hideIcon from "assets/images/icon-hide.svg";
import tipIcon from "assets/images/global/tip-icon.svg";
import arrowDownIcon from "assets/images/global/select-arrow.svg";
import eNotasIcon from "assets/images/global/enotas.svg";

// Store
import { useSettings } from "../../store/settings";

//Styles
import * as styled from "./details-styles";
import * as indexStyled from "../../styles/index-styles";

interface User {
  id: number;
  name: string;
  username: string;
  password: string;
  permission: {
    entityId: number;
    entityType: string;
    role: number;
  };
}

interface Store {
  id: number;
  socialName: string;
  identificationNumber: string;
  status: string;
  name?: string;
  tradingName?: string;
  phone?: string;
  zipcode?: string;
  address?: string;
  reference?: string;
  district?: string;
  city?: string;
  state?: number;
  logoPath?: string;
}

type Business = {
  legalName: string;
};

interface StoreToList {
  id: number;
  name: string;
  socialName: string;
  tradingName: string;
  identificationNumber: string;
  phone: string;
  zipcode: string;
  address: string;
  reference: string;
  district: string;
  city: string;
  state: number;
  logoPath: string;
  status: string;
}

interface FeeItem {
  amount: number;
  percentage: number;
}

interface CreditCardFee {
  percentage: number;
  amount: number;
}

type FeeItemFromAPI = {
  cardBrand: string;
  fixedAmount: number;
  numberOfInstallments: number;
  paymentType: string;
  percentage: number;
  networkIcon: string;
};

const SettingsDetails = () => {
  const [inputType, setInputType] = useState("password");
  const [hideShowPassIcon, setHideShowPassIcon] = useState(eyeIcon);

  const [files, setFiles] = useState<any>([]);
  const [idUser, setIdUser] = useState(0);
  const [businessId, setBusinessId] = useState(0);
  const [businessAccess, setBusinessAccess] = useState<number | undefined>(
    undefined
  );
  const [storeAcces, setStoreAcces] = useState<number | undefined>(undefined);
  const [storeId, setStoreId] = useState(0);
  const [logoPathState, setLogoPathState] = useState("");
  const [stateName, setStateName] = useState("");
  const [widthLoading, setWidthLoading] = useState(75);
  const [users, setUsers] = useState<User[]>([]);
  const [isUsersLoaded, setIsUsersLoaded] = useState(false);
  const [isStoresLoaded, setIsStoresLoaded] = useState(false);
  const [createNewUser, setCreateNewUser] = useState(false);
  const [editUser, setEditUser] = useState(false);
  const [entityTypeState, setEntityTypeState] = useState("");
  const [stores, setStores] = useState<Store[]>([]);
  const [storesToList, setStoresToList] = useState<StoreToList[]>([]);
  const [businessInfo, setBusinessInfo] = useState<Business>({
    legalName: "",
  });

  const [hasFees, setHasFees] = useState(false);

  const [visibleEditForm, setVisibleEditForm] = useState(false);
  const [visibleUserId, setVisibleUserId] = useState(0);

  const [visibleEditStore, setVisibleEditStore] = useState(false);
  const [visibleStoreId, setVisibleStoreId] = useState(0);

  const [isProcessing, setIsProcessing] = useState(false);
  const [isEditProcessing, setIsEditProcessing] = useState(false);
  const [isProcessingStore, setIsProcessingStore] = useState(false);
  const [savingEditBusiness, setSavingEditBusiness] = useState(false);
  const [addingEnotasKey, setAddingEnotasKey] = useState(false);
  const [enotasKey, setEnotasKey] = useState("");
  const [enotasBusinessId, setEnotasBusinessId] = useState("");
  const [iDForStoreModal, setIDForStoreModal] = useState("");
  const [feesType, setFeesType] = useState("brazil-fees");
  const [userFeesObject, setUserFeesObject] = useState<FeeItemFromAPI[]>([]);
  const [boletoFees, setBoletoFees] = useState({
    fixedAmount: 0,
    paymentType: "Boleto",
    percentage: 0,
  });
  const [pixFees, setPixFees] = useState({
    fixedAmount: 0,
    numberOfInstallments: 1,
    paymentType: "Pix",
    percentage: 0.55,
  });

  const [minFee, setMinFee] = useState<CreditCardFee[]>([]);

  const [enotasData, setEnotasData] = useState({
    identificationNumber: "",
    invoiceIssuerApiKey: "",
  });

  const [encodedNewStoreLogo, setEncodedNewStoreLogo] = useState<string | null>(
    ""
  );

  const [edittingBusinessData, setEdittingBusinessData] = useState(false);

  const [errorMessageCreateUser, setErrorMessageCreateUser] = useState("");

  const [createUserErrors, setCreateUserErrors] = useState({
    username: "",
    password: "",
  });

  const refState = useRef<HTMLSelectElement>(null);

  const [isFeeItemOpen, setIsFeeItemOpen] = useState(false);
  const [everyEnotas, setEveryEnotas] = useState(false);

  let installmentsArray = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];

  const [passWords, setPasswords] = useState({
    newPass: "",
    oldPass: "",
  });
  const [personalData, setPersonalData] = useState({
    id: 0,
    username: "",
    name: "",
    pass: "",
  });
  const [companyData, setCompanyData] = useState({
    businessName: "",
    tradingName: "",
    identificationNumber: "",
    phone: "",
    zipcode: "",
    address: "",
    reference: "",
    district: "",
    city: "",
    state: 28,
    logoPath: "",
  });

  const [newUser, setNewUser] = useState({
    name: "",
    username: "",
    password: "",
    permission: {
      entityId: 0,
      entityType: "Store",
      role: 0,
    },
  });

  const [edittedUser, setEdittedUser] = useState({
    id: 0,
    name: "",
    username: "",
    password: "",
    permission: {
      entityId: 0,
      entityType: "",
      role: 0,
    },
  });

  const [userFees, setUserFees] = useState({
    boleto: [0, 0],
    pix: [0, 0],
    creditInCashVisaMaster: [0, 0],
    creditInCashEloOthers: [0, 0],
    credit2To6xVisaMaster: [0, 0],
    credit2To6xEloOthers: [0, 0],
    credit7To12xVisaMaster: [0, 0],
    credit7To12xEloOthers: [0, 0],
    anticipation: [0, 0],
  });

  const [editting, setEditting] = useState(false);
  const [edittingUserData, setEdittingUserData] = useState(false);

  const { status } = useSettings((state) => state.settings);
  

  let intl = useIntl();
  const history = useHistory();
  let location = useLocation();

  function handleEdit() {
    if (editting) {
      setEditting(false);
    } else {
      setEditting(true);
    }
  }

  useEffect(() => {
    const { logoPath } = JSON.parse(
      localStorage.getItem("@PrimefySidebarInfos") || "{}"
    );

    setFiles(logoPath);
  }, []);

  useEffect(() => {
    if (refState.current) {
      refState.current.value = companyData.state.toString();
    }
  }, [editting]);

  function handleEditUserData() {
    edittingUserData ? setEdittingUserData(false) : setEdittingUserData(true);
  }

  useEffect(() => {
    const session = JSON.parse(localStorage.getItem("session") || "{}");
    if (session.entityType === "Business") {
      setEntityTypeState("Business");
      setBusinessId(session.businessId);
    } else if (session.entityType === "Administrator") {
      setEntityTypeState("Administrator");
    } else {
      setEntityTypeState("Store");
      setStoreId(session.storeId);
    }
    console.log( session.entityType, "o que")
  }, []);
  
  useEffect(() => {
    setIsStoresLoaded(false);

    StoreService.getStores()
      .then((response) => {
        setStoresToList(response.data);
        setStores(response.data);
        setIsStoresLoaded(true);

        let everyHaveGenerateInvoice = response.data.every((store: any) => {
          return store.generateInvoice;
        });

        setEveryEnotas(everyHaveGenerateInvoice);
      })
      .catch((err) => {
        setIsStoresLoaded(false);
      });

    UserProfileService.userFees().then((response) => {
      if (response.data.length === 0) {
      } else {
        let boletoFees: FeeItem = {
          amount: 0,
          percentage: 0,
        };

        let creditInCashVisaMasterFees: FeeItem = {
          amount: 0,
          percentage: 0,
        };

        let credit2To6xVisaMasterFees: FeeItem = {
          amount: 0,
          percentage: 0,
        };

        let credit7To12xVisaMasterFees: FeeItem = {
          amount: 0,
          percentage: 0,
        };

        let creditInCashEloOthersFees: FeeItem = {
          amount: 0,
          percentage: 0,
        };

        let credit2To6xEloOthersFees: FeeItem = {
          amount: 0,
          percentage: 0,
        };

        let credit7To12xEloOthersFees: FeeItem = {
          amount: 0,
          percentage: 0,
        };

        let creditCardFees: CreditCardFee[] = [];
        const userFees = response.data.reduce(
          (accValue: any, itemValue: any) => {
            if (itemValue.numberOfInstallments && itemValue.cardBrand) {
              if (!accValue[itemValue.numberOfInstallments]) {
                accValue[itemValue.numberOfInstallments] = [];
              }

              let creditCardNetworkIcon = creditCardNetwork.filter(
                (network) => {
                  if (network.network === itemValue.cardBrand) {
                    return network;
                  }
                }
              );

              if (
                itemValue.cardBrand === "Mastercard" &&
                itemValue.numberOfInstallments
              ) {
                creditCardFees.push({
                  percentage: itemValue.percentage,
                  amount: itemValue.fixedAmount,
                });
              }

              setMinFee(creditCardFees);

              let itemWithIcon = { ...itemValue };
              itemWithIcon.networkIcon = creditCardNetworkIcon[0].icon;
              accValue[itemValue.numberOfInstallments].push(itemWithIcon);
            }

            return accValue;
          },
          []
        );

        let boletoFixedFee = response.data.find((fee: any) => {
          if (fee.paymentType === "Boleto") {
            return fee;
          }
        });

        let pixFee = response.data.find((fee: any) => {
          if (fee.paymentType === "Pix") {
            return fee;
          }
        });

        if (pixFee) {
          setPixFees(pixFee);
        }

        setBoletoFees(boletoFixedFee);

        setUserFeesObject(userFees);

        response.data.map((item: any) => {
          if (item.paymentType === "Boleto") {
            boletoFees.amount = item.fixedAmount;
            boletoFees.percentage = item.percentage;
          }

          if (item.paymentType === "Credit" && item.cardBrand === "Visa") {
            if (item.numberOfInstallments === 1) {
              creditInCashVisaMasterFees.amount = item.fixedAmount;
              creditInCashVisaMasterFees.percentage = item.percentage;
            }

            if (item.numberOfInstallments === 2) {
              credit2To6xVisaMasterFees.amount = item.fixedAmount;
              credit2To6xVisaMasterFees.percentage = item.percentage;
            }

            if (item.numberOfInstallments === 7) {
              credit7To12xVisaMasterFees.amount = item.fixedAmount;
              credit7To12xVisaMasterFees.percentage = item.percentage;
            }
          }

          if (item.paymentType === "Credit" && !item.cardBrand) {
            if (item.numberOfInstallments === 1) {
              creditInCashEloOthersFees.amount = item.fixedAmount;
              creditInCashEloOthersFees.percentage = item.percentage;
            }

            if (item.numberOfInstallments === 2) {
              credit2To6xEloOthersFees.amount = item.fixedAmount;
              credit2To6xEloOthersFees.percentage = item.percentage;
            }

            if (item.numberOfInstallments === 7) {
              credit7To12xEloOthersFees.amount = item.fixedAmount;
              credit7To12xEloOthersFees.percentage = item.percentage;
            }
          }
        });

        setUserFees({
          boleto: [boletoFees.percentage, boletoFees.amount],
          pix: [0, 0],
          creditInCashVisaMaster: [
            creditInCashVisaMasterFees.percentage,
            creditInCashVisaMasterFees.amount,
          ],
          creditInCashEloOthers: [
            creditInCashEloOthersFees.percentage,
            creditInCashEloOthersFees.amount,
          ],
          credit2To6xVisaMaster: [
            credit2To6xVisaMasterFees.percentage,
            credit2To6xVisaMasterFees.amount,
          ],
          credit2To6xEloOthers: [
            credit2To6xEloOthersFees.percentage,
            credit2To6xEloOthersFees.amount,
          ],
          credit7To12xVisaMaster: [
            credit7To12xVisaMasterFees.percentage,
            credit7To12xVisaMasterFees.amount,
          ],
          credit7To12xEloOthers: [
            credit7To12xEloOthersFees.percentage,
            credit7To12xEloOthersFees.amount,
          ],
          anticipation: [0, 0],
        });

        setHasFees(true);
      }
    });
  }, []);

  useEffect(() => {
    setAllData();
  }, []);

  function setAllData() {
    const session = JSON.parse(localStorage.getItem("session") || "{}");

    switch (session.role) {
      case "Business.Owner":
      case "Store.Owner":
      case "Administrator.Owner":
        setBusinessAccess(0);
        setStoreAcces(0);
        break;
      case "Business.Administrator":
      case "Store.Administrator":
        setBusinessAccess(1);
        setStoreAcces(1);
        break;
      case "Business.Agent":
      case "Store.Agent":
        setBusinessAccess(2);
        setStoreAcces(2);
        break;
      case "Business.ViewOnly":
      case "Store.ViewOnly":
        setBusinessAccess(3);
        setStoreAcces(3);
        break;
      case "Business.API":
      case "Store.API":
        setBusinessAccess(4);
        setStoreAcces(4);
        break;
      default:
        setBusinessAccess(undefined);
        setStoreAcces(undefined);
    }

    StoreService.getInfos().then((response) => {
      setBusinessInfo({
        legalName: response.data.name,
      });

      // UserProfileService.getInfos().then((response2) => {
      //   const hidenPassArray: string[] = [];
      //   for (let i = 1; i <= response2.data.countCaracteres; i++) {
      //     hidenPassArray.push("*");
      //   }
      //   setIdUser(response2.data.id);

      //   setPersonalData(() => {
      //     return {
      //       username: response2.data.username,
      //       id: response2.data.id,
      //       name: response2.data.name,
      //       pass: hidenPassArray.join(""),
      //     };
      //   });
      // });

      setIsUsersLoaded(false);

      UserProfileService.getUsers()
        .then((responseUsers) => {
          const usersArray: User[] = [];
          responseUsers.data.forEach((user: any) => {
            const userItem = {
              id: user.id,
              name: user.name,
              username: user.username,
              password: user.password,
              permission: {
                entityId: user.permission.entityId,
                entityType: user.permission.entityType,
                role: user.permission.role,
              },
            };

            usersArray.push(userItem);
          });

          setUsers(usersArray);
          setIsUsersLoaded(true);
        })
        .catch((err) => {
          console.log(err);

          setIsUsersLoaded(false);
        });
    });
  }

  function changeBusinessData(name: string, value: string) {
    if (name === "newPass" || name === "oldPass") {
      setPasswords((prevState) => {
        return {
          ...prevState,
          [name]: value,
        };
      });
    } else {
      setPersonalData((prevState) => {
        return {
          ...prevState,
          [name]: value,
        };
      });
    }
  }

  function changeBusinessInfo(name: string, value: string) {
    setBusinessInfo((prevState) => {
      return {
        ...prevState,
        [name]: value,
      };
    });
  }

  function changeBusinessAddressData(name: string, value: string) {
    setBusinessData((prevState) => {
      return {
        ...prevState,
        address: {
          ...prevState.address,
          [name]: value,
        },
      };
    });
  }

  function changeNewUserdata(name: string, value: string) {
    if (name === "permission") {
      setNewUser((prevState) => {
        return {
          ...prevState,
          permission: {
            ...prevState.permission,
            role: parseInt(value),
          },
        };
      });
    } else if (name === "entityType") {
      setNewUser((prevState) => {
        return {
          ...prevState,
          permission: {
            ...prevState.permission,
            entityType: value,
          },
        };
      });
    } else {
      setNewUser((prevState) => {
        return {
          ...prevState,
          [name]: value,
        };
      });
    }

    if (name === "store") {
      setNewUser((prevState) => {
        return {
          ...prevState,
          permission: {
            ...prevState.permission,
            entityId: parseInt(value),
          },
        };
      });
    } else {
      setNewUser((prevState) => {
        return {
          ...prevState,
          permission: {
            ...prevState.permission,
            entityId: entityTypeState === "Business" ? businessId : storeId,
          },
        };
      });
    }
  }

  function changeEdittedUser(name: string, value: string) {
    console.log(name)
    if (name === "permission") {
      setEdittedUser((prevState) => {
        return {
          ...prevState,
          permission: {
            ...prevState.permission,
            role: parseInt(value),
          },
        };
      });
    } else if (name === "entityType") {
      setEdittedUser((prevState) => {
        return {
          ...prevState,
          permission: {
            ...prevState.permission,
            entityType: value,
          },
        };
      });
    } else {
      setEdittedUser((prevState) => {
        return {
          ...prevState,
          [name]: value,
        };
      });
    }

    if (name === "store") {
      setEdittedUser((prevState) => {
        return {
          ...prevState,
          permission: {
            ...prevState.permission,
            entityId: parseInt(value),
          },
        };
      });
    } else {
      setEdittedUser((prevState) => {
        return {
          ...prevState,
          permission: {
            ...prevState.permission,
            entityId: entityTypeState === "Business" ? businessId : storeId,
          },
        };
      });
    }
  }

  function changeCompanyData(name: string, value: string) {
    if (name === "state") {
      setCompanyData((prevState) => {
        return {
          ...prevState,
          [name]: parseInt(value),
        };
      });
    } else {
      setCompanyData((prevState) => {
        return {
          ...prevState,
          [name]: value,
        };
      });
    }
  }

  function savePersonalData() {
    if (passWords.newPass.length <= 8) {
      setCreateUserErrors((prevState) => {
        return {
          ...prevState,
          password: "user.profile.team.create.user.error.password",
        };
      });
    } else if (passWords.oldPass !== "" && passWords.newPass !== "") {
      const personalDataIntern = {
        id: idUser.toString(),
        name: personalData.name,
        username: personalData.username,
        confirmPassword: passWords.oldPass,
        password: passWords.newPass,
      };

      UserProfileService.putUserInfos(idUser, personalDataIntern)
        .then((response) => {
          setEdittingUserData(false);
          setAllData();
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      const personalDataIntern = {
        id: idUser.toString(),
        name: personalData.name,
        username: personalData.username,
        confirmPassword: null,
        password: null,
      };

      UserProfileService.putUserInfos(idUser, personalDataIntern)
        .then((response) => {
          setEdittingUserData(false);
          setAllData();
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }

  function getBase64(file: any, cb: any) {
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      cb(reader.result);
    };
    reader.onerror = function (error) {
      console.log("Error: ", error);
    };
  }

  function handleStoreLogo(e: React.ChangeEvent<HTMLInputElement>) {
    let file = "";
    if (e.target.files) {
      getBase64(e.target.files[0], (result: any) => {
        file = result;
        setEncodedNewStoreLogo(file);
      });
    }
  }

  function editStore(id: number, socialName: string) {
    setIsProcessingStore(true);

    let newStoreData = {
      socialName: socialName,
      encodedLogoImage: encodedNewStoreLogo,
    };

    UserProfileService.putStore(id, newStoreData)
      .then((response) => {
        setVisibleEditStore(false);
        setIsStoresLoaded(false);

        setIsProcessingStore(false);

        const sidebarInfos = JSON.parse(
          localStorage.getItem("@PrimefySidebarInfos") || "{}"
        );

        let newSidebarInfos = {
          ...sidebarInfos,
          logoPath: response.data.logoPath,
        };

        localStorage.removeItem("@PrimefySidebarInfos");
        localStorage.setItem(
          "@PrimefySidebarInfos",
          JSON.stringify(newSidebarInfos)
        );

        StoreService.getStores().then((response) => {
          setStoresToList(response.data);
          setStores(response.data);
          setIsStoresLoaded(true);
        });
      })
      .catch((err) => {
        setIsProcessingStore(false);
        console.log(err);
      });
  }

  function handleCreateNewStore() {
    history.push("/create-store");
  }

  function handleCreateNewUser() {
    setCreateNewUser(true);

    setErrorMessageCreateUser("");

    setVisibleEditForm(false);
    setVisibleUserId(0);
  }

  const [newUserErrors, setNewUserErrors] = useState<string[]>([]);
  const [editUserErrors, setEditUserErrors] = useState<string[]>([]);

  function validate() {
    let errors = [];

    if (newUser.password.length <= 8) {
      errors.push("senha");
    }

    if (newUser.name.length <= 0) {
      errors.push("invalidName");
    }

    const emailExpression = /[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,10}$/;
    if (
      newUser.username.length <= 8 ||
      !emailExpression.test(newUser.username)
    ) {
      errors.push("invalidUsername");
    }

    if (
      newUser.permission.entityType === "Store" &&
      newUser.permission.entityId === 0
    ) {
      errors.push("store");
    }

    if (
      newUser.permission.entityType === "Administrator" &&
      !/@premepay/.test(newUser.username)
    ) {
      errors.push("administrator");
    }

    users.some(function (ele, val) {
      if (ele.username === newUser.username) {
        errors.push("username");
      }
    });

    setNewUserErrors(errors);

    return errors.length === 0;
  }
  
  function validateEdit() {
    
    let errors = [];
    
    if (edittedUser.password === undefined) {
      errors.push("senha");
    }

    if (edittedUser.password !== undefined && edittedUser.password.length <= 8) {
      errors.push("senha");
    }

    if (edittedUser.name.length <= 0) {
      errors.push("invalidName");
    }

    const emailExpression = /[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,10}$/;
    if (
      edittedUser.username.length <= 8 ||
      !emailExpression.test(edittedUser.username)
    ) {
      errors.push("invalidUsername");
    }
    
    if (
      edittedUser.permission &&
      edittedUser.permission.entityType === "Store" &&
      (edittedUser.permission.entityId ===  0 ||
        (storeId !== undefined && storeId !==  0 && edittedUser.permission.entityId !== storeId))
    ) {
      errors.push("store");
    }

    if (
      edittedUser.permission &&
      edittedUser.permission.entityType === "Business" &&
      (edittedUser.permission.entityId ===  0 ||
        (businessId !== undefined && businessId !==  0 && edittedUser.permission.entityId !== businessId))
    ) {
      errors.push("store");
    }

    if (
      edittedUser.permission.entityType === "Administrator" &&
      !/@premepay/.test(edittedUser.username)
    ) {
      errors.push("administrator");
    }
    console.log(errors);
    setEditUserErrors(errors);

    return errors.length === 0;
  }

  function submitNewUser(event: React.SyntheticEvent) {
    event.preventDefault();
    setErrorMessageCreateUser("");

    if (validate()) {
      setIsProcessing(true);

      UserProfileService.postUser(newUser)
        .then((response) => {
          setIsProcessing(false);

          setNewUser({
            name: "",
            username: "",
            password: "",
            permission: {
              entityId: 0,
              entityType: "Store",
              role: 0,
            },
          });

          UserProfileService.getUsers()
            .then((responseUsers) => {
              const usersArray: User[] = [];
              responseUsers.data.forEach((user: any) => {
                const userItem = {
                  id: user.id,
                  name: user.name,
                  username: user.username,
                  password: user.password,
                  permission: {
                    entityId: user.permission.entityId,
                    entityType: user.permission.entityType,
                    role: user.permission.role,
                  },
                };

                usersArray.push(userItem);
              });

              setUsers(usersArray);
              setAllData()
              setIsUsersLoaded(true);
            })
            .catch((err) => {
              console.log(err);

              setIsUsersLoaded(false);
            });

          setCreateNewUser(false);
        })
        .catch((err) => {
          console.log(err);
          setIsProcessing(false);

          setErrorMessageCreateUser(
            "user.profile.team.create.user.error.create.try.again"
          );
        });
    }
  }

  function toggleEditUser(id: number, user: User) {
    if (visibleEditForm && visibleUserId === id) {
      if (validateEdit()) {
        setVisibleEditForm(false);
        setVisibleUserId(0);
      }
    } else {
      setVisibleEditForm(true);
      setVisibleUserId(id);
  
      setEdittedUser({
        id: user.id,
        name: user.name,
        username: user.username,
        password: user.password,
        permission: {
          entityId: user.permission.entityId,
          entityType: user.permission.entityType,
          role: user.permission.role,
        },
      });
    }
  }

  function toggleEditBusiness(id: number, store: any) {
    if (visibleEditStore && visibleStoreId !== id) {
      setVisibleStoreId(id);
    }

    if (visibleEditStore && visibleStoreId !== id) {
      setVisibleEditStore(false);
      setVisibleStoreId(0);
    } else {
      setVisibleEditStore(true);
      setVisibleStoreId(id);

      setCompanyData((prevState) => {
        return {
          ...prevState,
          businessName: store.name,
          tradingName: store.socialName,
          identificationNumber: store.identificationNumber,
          phone: store.phone,
        };
      });

      if (store.address) {
        setCompanyData((prevState) => {
          return {
            ...prevState,
            zipcode: store.address.zipCode,
            address: store.address.street,
            reference: store.address.reference,
            district: store.address.district,
            city: store.address.city,
            state: store.address.state.toString(),
            logoPath: store.logoPath,
          };
        });
      }

      setLogoPathState(store.logoPath);

      // setEdittedUser({
      //   id: user.id,
      //   name: user.name,
      //   username: user.username,
      //   password: user.password,
      //   permission: {
      //     entityId: user.permission.entityId,
      //     entityType: user.permission.entityType,
      //     role: user.permission.role
      //   }
      // });
    }
  }

  function updateUser(id: number) {
    // event.preventDefault();
    setErrorMessageCreateUser("");

    if (validateEdit()) {
      setIsEditProcessing(true);

      UserProfileService.updateUser(id, edittedUser)
        .then((response) => {
          setVisibleEditForm(false);
          setVisibleUserId(0);

          setIsEditProcessing(false);
          UserProfileService.getUsers()
            .then((responseUsers) => {
              const usersArray: User[] = [];
              responseUsers.data.forEach((user: any) => {
                const userItem = {
                  id: user.id,
                  name: user.name,
                  username: user.username,
                  password: user.password,
                  permission: {
                    entityId: user.permission.entityId,
                    entityType: user.permission.entityType,
                    role: user.permission.role,
                  },
                };
                usersArray.push(userItem);
              });

              setUsers(usersArray);
              setIsUsersLoaded(true);
            })
            .catch((err) => {
              console.log(err);

              setIsUsersLoaded(false);
            });
        })
        .catch((err) => {
          console.log(err);
          setIsEditProcessing(false);

          setCreateUserErrors((prevState) => {
            return {
              ...prevState,
              username: "user.profile.team.create.user.error.already.exists",
            };
          });

          setErrorMessageCreateUser(
            "user.profile.team.create.user.error.update.try.again"
          );
        });
    }
  }

  function cancelEdit() {
    setVisibleEditForm(false);
    setVisibleUserId(0);

    UserProfileService.getUsers()
      .then((responseUsers) => {
        const usersArray: User[] = [];
        responseUsers.data.forEach((user: any) => {
          const userItem = {
            id: user.id,
            name: user.name,
            username: user.username,
            password: user.password,
            permission: {
              entityId: user.permission.entityId,
              entityType: user.permission.entityType,
              role: user.permission.role,
            },
          };

          usersArray.push(userItem);
        });

        setUsers(usersArray);
        setIsUsersLoaded(true);
      })
      .catch((err) => {
        console.log(err);

        setIsUsersLoaded(false);
      });
  }

  function handleDeleteUser(id: number, entityType: string, entityId: number) {
    setErrorMessageCreateUser("");

    UserProfileService.deleteUser(id, entityType, entityId).then((response) => {
      UserProfileService.getUsers()
        .then((responseUsers) => {
          const usersArray: User[] = [];
          responseUsers.data.forEach((user: any) => {
            const userItem = {
              id: user.id,
              name: user.name,
              username: user.username,
              password: user.password,
              permission: {
                entityId: user.permission.entityId,
                entityType: user.permission.entityType,
                role: user.permission.role,
              },
            };

            usersArray.push(userItem);
          });

          setUsers(usersArray);
          setAllData()
          setIsUsersLoaded(true);
        })
        .catch((err) => {
          console.log(err);
          setIsUsersLoaded(false);
        });
    });
  }

  function toggleShowPassword() {
    if (inputType === "password") {
      setInputType("text");
      setHideShowPassIcon(hideIcon);
    } else {
      setHideShowPassIcon(eyeIcon);
      setInputType("password");
    }
  }

  const [businessData, setBusinessData] = useState({
    legalName: "Dark Media Group Ltda",
    tradingName: "Dark Media",
    statementDescriptor: "Descriptor",
    category: "Category",
    website: "www.darkmediagroup.com.br",
    phone: "(63) 999589621",
    address: {
      street: "305 sul alameda 2",
      number: "0",
      reference: "",
      district: "não tem",
      city: "Palmas",
      state: "Tocantins",
      country: "Brasil",
      zipCode: "77015436",
    },
    logo: "https://preme.s3.us-east-2.amazonaws.com/logotipos/45083288-e32b-4c78-8e9e-0ed871eec409.png",
  });

  function changePersonalData(name: string, value: string) {
    if (name === "newPass" || name === "oldPass") {
      setPasswords((prevState) => {
        return {
          ...prevState,
          [name]: value,
        };
      });
    } else {
      setPersonalData((prevState) => {
        return {
          ...prevState,
          [name]: value,
        };
      });
    }
  }

  function handleEnotasData(name: string, value: string) {
    setEnotasData((prevState: any) => {
      return {
        ...prevState,
        [name]: [value],
      };
    });
  }

  return (
    <styled.LeftRightContainer>
      <styled.LeftSettings className="border-right">
        {status !== "enabled" && status !== "denied" ? (
          <styled.BoxDetails className="border-bottom flex-container">
            <styled.FlexHeader>
              <FormattedMessage id="finish.register.settings.title" />
            </styled.FlexHeader>

            <styled.Header>
              <p className="subtitle">
                {intl.formatMessage({
                  id: "finish.register.settings.subtitle",
                })}
              </p>
            </styled.Header>

            <styled.DetailsItems>
              <Buttons
                mode="filled"
                label={intl.formatMessage({
                  id: "finish.register.settings.button",
                })}
                handler={() => history.push("/store-register")}
              />
            </styled.DetailsItems>
          </styled.BoxDetails>
        ) : null}
        {/* <styled.BoxDetails className="border-bottom flex-container hidden-desktop">
          <styled.FlexHeader>
            <FormattedMessage id="user.profile.page.notification.title" />

            <div className="containar--50-max">
              <SettingsNotificationButtonModal />
            </div>
          </styled.FlexHeader>

          <styled.Header>
            <p className="subtitle">{intl.formatMessage({ id: "user.profile.page.notification.subtitle" })}</p>
          </styled.Header>

          <styled.DetailsItems>
            <div>
              <Input required={false} mode="checkbox" name="notifications" value="success-payments" placeholder={intl.formatMessage({ id: "user.profile.notifications.success.payments" })} handler={() => { }} />
              <Input required={false} mode="checkbox" name="notifications" value="payouts" placeholder={intl.formatMessage({ id:  "user.profile.notifications.payouts" })} handler={() => { }} />
              <Input required={false} mode="checkbox" name="notifications" value="disputes" placeholder={intl.formatMessage({ id:  "user.profile.notifications.disputes" })} handler={() => { }} />
              <Input required={false} mode="checkbox" name="notifications" value="refunds" placeholder={intl.formatMessage({ id: "user.profile.notifications.refunds" })} handler={() => { }} />
              <Input required={false} mode="checkbox" name="notifications" value="failed-payments" placeholder={intl.formatMessage({ id: "user.profile.notifications.failed.payments" })} handler={() => { }} />
            </div>
          </styled.DetailsItems>
        </styled.BoxDetails> */}

        {/* <styled.BoxDetails className="border-bottom">
          <styled.Header>
            <FormattedMessage id="settings.business.information.title" />

            <p className="subtitle">{intl.formatMessage({ id: "settings.business.information.text" })}</p>
          </styled.Header>
          <styled.DetailsItems>
            {!edittingBusinessData && <><div className="info-detail-item">
              <h5>{intl.formatMessage({ id: "settings.business.information.legal.business.name" })}</h5>
              <p>{businessInfo.legalName}</p>
            </div> */}

        {/* <div className="info-detail-item">
                <h5>{intl.formatMessage({ id: "settings.business.information.trading.name" })}</h5>
                <p>{businessData.tradingName}</p>
              </div> */}
        {/* 
              <div className="info-detail-item">
                <div>
                  <h5>{intl.formatMessage({ id: "settings.business.information.statement.descriptor" })}</h5>

                  <img src={tipIcon} alt="tip" />
                </div>

                <p>{businessData.statementDescriptor}</p>
              </div>

              <div className="info-detail-item">
                <div>
                  <h5>{intl.formatMessage({ id: "settings.business.information.category" })}</h5>

                  <img src={tipIcon} alt="tip" />
                </div>

                <p>{businessData.category}</p>
              </div>

              <div className="info-detail-item">
                <h5>{intl.formatMessage({ id: "settings.business.information.website" })}</h5>
                <p>{businessData.website}</p>
              </div>

              <div className="info-detail-item">
                <h5>{intl.formatMessage({ id: "settings.business.information.phone" })}</h5>
                <p>{businessData.phone}</p>
              </div>

              <div className="info-detail-item">
                <h5>{intl.formatMessage({ id: "settings.business.information.address" })}</h5>
                <p>{businessData.address.street}</p>
                <p>{businessData.address.number}</p>
                <p>{businessData.address.reference}</p>
                <p>{businessData.address.zipCode}</p>
                <p>{businessData.address.city}</p>
                <p>{businessData.address.state}</p>
              </div>

              <div className="info-detail-item">
                <h5>{intl.formatMessage({ id: "settings.business.information.logo" })}</h5>
                <p>
                  <img src={businessData.logo} alt="business logo" />
                </p>
              </div> */}
        {/* </>}

            {edittingBusinessData && <>
              <div className="info-detail-item">
                <h5>{intl.formatMessage({ id: "settings.business.information.legal.business.name" })}</h5>
                <div className="input-container-settings">
                  <Input required={false} mode="named" name="legalName" value={businessInfo.legalName} placeholder="" handler={changeBusinessInfo} />
                </div>
              </div> */}

        {/* <div className="info-detail-item">
                <h5>{intl.formatMessage({ id: "settings.business.information.trading.name" })}</h5>
                <div className="input-container-settings">
                  <Input required={false} mode="named" name="tradingName" value={businessData.tradingName} placeholder="" handler={changeBusinessData} />
                </div>
              </div> */}

        {/* <div className="info-detail-item">
                <div>
                  <h5>{intl.formatMessage({ id: "settings.business.information.statement.descriptor" })}</h5>

                  <img src={tipIcon} alt="tip" />
                </div>

                <div className="input-container-settings">
                  <Input required={false} mode="named" name="statementDescriptor" value={businessData.statementDescriptor} placeholder="" handler={changeBusinessData} />
                </div>
              </div>

              <div className="info-detail-item">
                <div>
                  <h5>{intl.formatMessage({ id: "settings.business.information.category" })}</h5>

                  <img src={tipIcon} alt="tip" />
                </div>

                <div className="input-container-settings">
                  <Input required={false} mode="named" name="category" value={businessData.category} placeholder="" handler={changeBusinessData} />
                </div>
              </div>

              <div className="info-detail-item">
                <h5>{intl.formatMessage({ id: "settings.business.information.website" })}</h5>
                <div className="input-container-settings">
                  <Input required={false} mode="named" name="website" value={businessData.website} placeholder="" handler={changeBusinessData} />
                </div>
              </div>

              <div className="info-detail-item">
                <h5>{intl.formatMessage({ id: "settings.business.information.phone" })}</h5>
                <div className="input-container-settings">
                  <Input required={false} mode="named" name="phone" value={businessData.phone} placeholder="" handler={changeBusinessData} />
                </div>
              </div>

              <div className="info-detail-item">
                <h5>{intl.formatMessage({ id: "settings.business.information.address" })}</h5>
                <div className="input-container-settings">
                  <Input required={false} mode="named" name="street" value={businessData.address.street} placeholder="" handler={changeBusinessAddressData} />
                  <Input required={false} mode="named" name="number" value={businessData.address.number} placeholder="" handler={changeBusinessAddressData} />
                  <Input required={false} mode="named" name="reference" value={businessData.address.reference} placeholder="" handler={changeBusinessAddressData} />
                  <Input required={false} mode="named" name="zipCode" value={businessData.address.zipCode} placeholder="" handler={changeBusinessAddressData} />
                  <Input required={false} mode="named" name="city" value={businessData.address.city} placeholder="" handler={changeBusinessAddressData} />
                  <Input required={false} mode="named" name="state" value={businessData.address.state} placeholder="" handler={changeBusinessAddressData} />
                </div>
              </div> */}
        {/* </>}
            {!edittingBusinessData && <Buttons mode="filled" label={intl.formatMessage({ id: "settings.team.list.edit" })} handler={() => setEdittingBusinessData(!edittingBusinessData)} />}
            {edittingBusinessData && <><styled.Bottom>
              <Buttons mode="filled" label={intl.formatMessage({ id: "user.profile.ui.save" })} handler={() => { }} />
              <Buttons mode="unfilled" label={intl.formatMessage({ id: "user.profile.team.cancel.button.label" })} handler={() => setEdittingBusinessData(false)} />
            </styled.Bottom>
            </>}
          </styled.DetailsItems>
        </styled.BoxDetails> */}

        {edittingUserData && (
          <styled.BoxDetails>
            <styled.Header>
              <FormattedMessage id="user.profile.title" />

              <Button
                label={intl.formatMessage({ id: "user.profile.ui.save" })}
                icon={buttonEdit}
                handler={savePersonalData}
              />
            </styled.Header>

            <styled.DetailsItems>
              <div className="detail-item--margin-bottom">
                <Input
                  required={false}
                  mode="named"
                  name="username"
                  value={personalData.username}
                  placeholder={intl.formatMessage({ id: "user.profile.email" })}
                  handler={changeBusinessData}
                />
              </div>

              <div className="detail-item--margin-bottom">
                <Input
                  required={false}
                  mode="named"
                  name="name"
                  value={personalData.name}
                  placeholder={intl.formatMessage({ id: "user.profile.name" })}
                  handler={changeBusinessData}
                />
              </div>

              <div className="detail-item--margin-bottom">
                <Input
                  required={false}
                  mode="named"
                  name="oldPass"
                  value={passWords.oldPass}
                  placeholder={intl.formatMessage({
                    id: "user.profile.old.password",
                  })}
                  handler={changeBusinessData}
                />
              </div>

              <div className="detail-item--margin-bottom">
                <Input
                  required={false}
                  mode="named"
                  name="newPass"
                  value={passWords.newPass}
                  placeholder={intl.formatMessage({
                    id: "user.profile.new.password",
                  })}
                  handler={changeBusinessData}
                />
              </div>

              {createUserErrors.password !== "" && (
                <indexStyled.ErrorCompWithoutPadding>
                  {intl.formatMessage({ id: createUserErrors.password })}
                </indexStyled.ErrorCompWithoutPadding>
              )}
            </styled.DetailsItems>
          </styled.BoxDetails>
        )}

        {(businessAccess === 0 ||
          businessAccess === 1 ||
          storeAcces === 0 ||
          storeAcces === 1) && (
          <styled.BoxDetails className="border-bottom">
            <styled.Header>
              <FormattedMessage id="user.profile.team.title" />

              <p className="subtitle">
                {intl.formatMessage({ id: "settings.users.text" })}
              </p>
            </styled.Header>

            {createNewUser && (
              <indexStyled.EditUserContainer>
                {/* <form onSubmit={submitNewUser} className="details-items-input-100"> */}
                <styled.DetailsItems>
                  <div className="form-create-user">
                    <div className="detail-item--margin-bottom">
                      <h5>
                        {intl.formatMessage({ id: "user.profile.team.name" })}
                      </h5>

                      <Input
                        required={true}
                        bgColors="white"
                        mode="named"
                        name="name"
                        value={newUser.name}
                        placeholder=""
                        handler={changeNewUserdata}
                      />

                      {newUserErrors.some(
                        (error: string) => error === "invalidName"
                      ) ? (
                        <indexStyled.ErrorCompWithoutPadding>
                          {intl.formatMessage({
                            id: "settings.new.user.error",
                          })}
                        </indexStyled.ErrorCompWithoutPadding>
                      ) : null}
                    </div>

                    <div className="detail-item--margin-bottom">
                      <h5>
                        {intl.formatMessage({ id: "user.profile.team.email" })}
                      </h5>

                      <Input
                        required={true}
                        bgColors="white"
                        mode="named"
                        name="username"
                        value={newUser.username}
                        placeholder=""
                        handler={changeNewUserdata}
                      />
                      {newUserErrors.some(
                        (error: string) => error === "username"
                      ) ? (
                        <indexStyled.ErrorCompWithoutPadding>
                          {intl.formatMessage({
                            id: "user.profile.team.create.user.error.already.exists",
                          })}
                        </indexStyled.ErrorCompWithoutPadding>
                      ) : null}

                      {newUserErrors.some(
                        (error: string) => error === "invalidUsername"
                      ) ? (
                        <indexStyled.ErrorCompWithoutPadding>
                          {intl.formatMessage({
                            id: "settings.new.user.email.error",
                          })}
                        </indexStyled.ErrorCompWithoutPadding>
                      ) : null}
                    </div>

                    <div className="detail-item--margin-bottom">
                      <h5>
                        {intl.formatMessage({
                          id: "user.profile.team.password",
                        })}
                      </h5>

                      <Input
                        required={true}
                        bgColors="white"
                        mode="named"
                        name="password"
                        value={newUser.password}
                        placeholder=""
                        handler={changeNewUserdata}
                      />
                      {newUserErrors.some(
                        (error: string) => error === "senha"
                      ) ? (
                        <indexStyled.ErrorCompWithoutPadding>
                          {intl.formatMessage({
                            id: "user.profile.team.create.user.error.password",
                          })}
                        </indexStyled.ErrorCompWithoutPadding>
                      ) : null}
                    </div>

                    <div className="detail-item--margin-bottom">
                      <h5>
                        {intl.formatMessage({ id: "user.profile.team.access" })}
                      </h5>

                      <Select
                        required={true}
                        bgColors="white"
                        mode="named-child"
                        name="permission"
                        value={newUser.permission.role}
                        placeholder=""
                        handler={changeNewUserdata}
                      >
                        {/* <option value="">{intl.formatMessage({ id: "user.profile.team.select.default" })}</option> */}
                        {/* <option value="10"></option> */}
                        <option value="0">
                          {intl.formatMessage({
                            id: "user.profile.team.access.owner",
                          })}
                        </option>
                        <option value="1">
                          {intl.formatMessage({
                            id: "user.profile.team.access.administrator",
                          })}
                        </option>
                        <option value="2">
                          {intl.formatMessage({
                            id: "user.profile.team.access.agent",
                          })}
                        </option>
                        <option value="3">
                          {intl.formatMessage({
                            id: "user.profile.team.access.view.only",
                          })}
                        </option>
                        <option value="4">
                          {intl.formatMessage({
                            id: "user.profile.team.access.developer",
                          })}
                        </option>
                      </Select>

                      {newUserErrors.some(
                        (error: string) => error === "administrator"
                      ) ? (
                        <indexStyled.ErrorCompWithoutPadding>
                          {intl.formatMessage({
                            id: "user.profile.team.create.user.error.administrator",
                          })}
                        </indexStyled.ErrorCompWithoutPadding>
                      ) : null}
                    </div>

                    <div className="detail-item--margin-bottom">
                      <h5>
                        {intl.formatMessage({
                          id: "user.profile.team.entity.type",
                        })}
                      </h5>

                      <Select
                        required={true}
                        bgColors="white"
                        mode="named-child"
                        name="entityType"
                        value={newUser.permission.entityType}
                        placeholder=""
                        handler={changeNewUserdata}
                      >
                        {entityTypeState === "Administrator" && (
                          <option value="Administrator">
                            {intl.formatMessage({
                              id: "user.profile.team.access.administrator",
                            })}
                          </option>
                        )}
                        {(entityTypeState === "Business" ||
                          entityTypeState === "Administrator") && (
                          <option value="Business">
                            {intl.formatMessage({
                              id: "user.profile.team.access.business",
                            })}
                          </option>
                        )}
                        <option value="Store">
                          {intl.formatMessage({
                            id: "user.profile.team.access.store",
                          })}
                        </option>
                      </Select>
                    </div>

                    {newUser.permission.entityType === "Store" &&
                      (entityTypeState === "Business" ||
                        entityTypeState === "Administrator") && (
                        <div className="detail-item--margin-bottom">
                          <h5>
                            {intl.formatMessage({
                              id: "user.profile.team.store",
                            })}
                          </h5>

                          <Select
                            required={true}
                            bgColors="white"
                            mode="named-child"
                            name="store"
                            placeholder=""
                            handler={changeNewUserdata}
                          >
                            <option value="">
                              {intl.formatMessage({
                                id: "request.payment.select.label",
                              })}
                            </option>
                            {stores.map((store) => (
                              <option key={store.id} value={store.id}>
                                {store.socialName}
                              </option>
                            ))}
                          </Select>

                          {newUserErrors.some(
                            (error: string) => error === "store"
                          ) ? (
                            <indexStyled.ErrorCompWithoutPadding>
                              {intl.formatMessage({
                                id: "settings.new.user.store.error",
                              })}
                            </indexStyled.ErrorCompWithoutPadding>
                          ) : null}
                        </div>
                      )}
                  </div>
                </styled.DetailsItems>

                <styled.Bottom>
                  <div className="edit-version">
                    <Buttons
                      mode="unfilled"
                      label={intl.formatMessage({
                        id: "user.profile.team.cancel.button.label",
                      })}
                      handler={() => setCreateNewUser(false)}
                    />
                  </div>

                  {!isProcessing && (
                    <div className="header-right">
                      <Buttons
                        mode="filled-with-event"
                        label={intl.formatMessage({
                          id: "user.profile.team.create.account.button.label",
                        })}
                        icon={buttonEdit}
                        handler={submitNewUser}
                      />
                    </div>
                  )}

                  {isProcessing && (
                    <div className="header-right">
                      <SmallProcessingButton />
                    </div>
                  )}
                </styled.Bottom>

                {errorMessageCreateUser !== "" && (
                  <indexStyled.ErrorComp>
                    {intl.formatMessage({ id: errorMessageCreateUser })}
                  </indexStyled.ErrorComp>
                )}

                {/* </form> */}
              </indexStyled.EditUserContainer>
            )}

            {!createNewUser && (
              <styled.DetailsItems>
                <indexStyled.TableList>
                  <indexStyled.TableItems>
                    <indexStyled.FullTableUsers>
                      <thead>
                        <tr>
                          <th>
                            <FormattedMessage id="settings.team.list.header.user" />
                          </th>
                          <th>
                            <FormattedMessage id="settings.team.list.header.email" />
                          </th>
                          <th></th>
                        </tr>
                      </thead>

                      {users.length === 0 && isUsersLoaded && (
                        <h3>{intl.formatMessage({ id: "items.not.found" })}</h3>
                      )}

                      <tbody>
                        {isUsersLoaded ? (
                          users.map((user: User) => {
                            return (
                              <>
                                <tr key={user.id}>
                                  <td>{user.name}</td>
                                  <td>{user.username}</td>

                                  {((storeAcces === 0 &&
                                    businessAccess === 0) ||
                                    (storeAcces === 1 &&
                                      businessAccess === 1 &&
                                      user.permission.role > 0)) && (
                                    <td
                                      onClick={() =>
                                        toggleEditUser(user.id, user)
                                      }
                                    >
                                      <Buttons
                                        mode={"unfilled"}
                                        label={intl.formatMessage({
                                          id:
                                            visibleEditForm &&
                                            visibleUserId === user.id
                                              ? "user.profile.ui.save"
                                              : "settings.team.list.edit",
                                        })}
                                        handler={
                                          visibleEditForm &&
                                          visibleUserId === user.id
                                            ? (e: any) => {updateUser(user.id)}
                                            : () => {toggleEditUser(user.id, user)}
                                        }
                                      />
                                    </td>
                                  )}

                                  {storeAcces === 1 &&
                                    businessAccess === 1 &&
                                    user.permission.role === 0 && <td></td>}
                                </tr>

                                {visibleEditForm &&
                                  visibleUserId === user.id && (
                                    <tr className="height--550-user-edit-form">
                                      <indexStyled.EditUserContainer>
                                        <div className="detail-item--margin-bottom">
                                          <h5>
                                            {intl.formatMessage({
                                              id: "user.profile.team.name",
                                            })}
                                          </h5>

                                          <Input
                                            required={true}
                                            bgColors="white"
                                            mode="named"
                                            name="name"
                                            value={edittedUser.name}
                                            placeholder={intl.formatMessage({
                                              id: "user.profile.team.name",
                                            })}
                                            handler={changeEdittedUser}
                                          />

                                          {editUserErrors.some(
                                            (error: string) =>
                                              error === "invalidName"
                                          ) ? (
                                            <indexStyled.ErrorCompWithoutPadding>
                                              {intl.formatMessage({
                                                id: "settings.new.user.error",
                                              })}
                                            </indexStyled.ErrorCompWithoutPadding>
                                          ) : null}
                                        </div>

                                        <div className="detail-item--margin-bottom">
                                          <h5>
                                            {intl.formatMessage({
                                              id: "user.profile.team.email",
                                            })}
                                          </h5>

                                          <Input
                                            required={true}
                                            bgColors="white"
                                            mode="named"
                                            name="username"
                                            value={edittedUser.username}
                                            placeholder={intl.formatMessage({
                                              id: "user.profile.team.email",
                                            })}
                                            handler={changeEdittedUser}
                                          />
                                          {editUserErrors.some(
                                            (error: string) =>
                                              error === "invalidUsername"
                                          ) ? (
                                            <indexStyled.ErrorCompWithoutPadding>
                                              {intl.formatMessage({
                                                id: "settings.new.user.email.error",
                                              })}
                                            </indexStyled.ErrorCompWithoutPadding>
                                          ) : null}
                                        </div>

                                        <div className="detail-item--margin-bottom">
                                          <h5>
                                            {intl.formatMessage({
                                              id: "user.profile.team.password",
                                            })}
                                          </h5>

                                          <Input
                                            required={true}
                                            bgColors="white"
                                            mode="named"
                                            type={inputType}
                                            name="password"
                                            value={edittedUser.password}
                                            placeholder={intl.formatMessage({
                                              id: "user.profile.team.password",
                                            })}
                                            handler={changeEdittedUser}
                                            icon={hideShowPassIcon}
                                            iconHandler={toggleShowPassword}
                                          />

                                          {editUserErrors.some(
                                            (error: string) => error === "senha"
                                          ) ? (
                                            <indexStyled.ErrorCompWithoutPadding>
                                              {intl.formatMessage({
                                                id: "user.profile.team.create.user.error.password",
                                              })}
                                            </indexStyled.ErrorCompWithoutPadding>
                                          ) : null}
                                        </div>

                                        {user.permission ? (
                                          (() => {
                                            return (
                                              <>
                                                <div className="detail-item--margin-bottom">
                                                  <h5>
                                                    {intl.formatMessage({
                                                      id: "user.profile.team.access",
                                                    })}
                                                  </h5>

                                                  <Select
                                                    required={true}
                                                    bgColors="white"
                                                    mode="named-child"
                                                    value={
                                                      edittedUser.permission
                                                        .role
                                                    }
                                                    name="permission"
                                                    placeholder={intl.formatMessage(
                                                      {
                                                        id: "user.profile.team.access",
                                                      }
                                                    )}
                                                    handler={changeEdittedUser}
                                                  >
                                                    <option value="10"></option>
                                                    <option value="0">
                                                      {intl.formatMessage({
                                                        id: "user.profile.team.access.owner",
                                                      })}
                                                    </option>
                                                    <option value="1">
                                                      {intl.formatMessage({
                                                        id: "user.profile.team.access.administrator",
                                                      })}
                                                    </option>
                                                    <option value="2">
                                                      {intl.formatMessage({
                                                        id: "user.profile.team.access.agent",
                                                      })}
                                                    </option>
                                                    <option value="3">
                                                      {intl.formatMessage({
                                                        id: "user.profile.team.access.view.only",
                                                      })}
                                                    </option>
                                                    <option value="4">
                                                      {intl.formatMessage({
                                                        id: "user.profile.team.access.developer",
                                                      })}
                                                    </option>
                                                  </Select>

                                                  {editUserErrors.some(
                                                    (error: string) =>
                                                      error === "administrator"
                                                  ) ? (
                                                    <indexStyled.ErrorCompWithoutPadding>
                                                      {intl.formatMessage({
                                                        id: "user.profile.team.create.user.error.administrator",
                                                      })}
                                                    </indexStyled.ErrorCompWithoutPadding>
                                                  ) : null}
                                                </div>

                                                <div className="detail-item--margin-bottom">
                                                  <h5>
                                                    {intl.formatMessage({
                                                      id: "user.profile.team.entity.type",
                                                    })}
                                                  </h5>

                                                  <Select
                                                    required={true}
                                                    bgColors="white"
                                                    mode="named-child"
                                                    value={
                                                      edittedUser.permission
                                                        .entityType
                                                    }
                                                    name="entityType"
                                                    placeholder={intl.formatMessage(
                                                      {
                                                        id: "user.profile.team.entity.type",
                                                      }
                                                    )}
                                                    handler={changeEdittedUser}
                                                  >
                                                    <option value=""></option>
                                                    <option value="Store">
                                                      {intl.formatMessage({
                                                        id: "user.profile.team.access.store",
                                                      })}
                                                    </option>
                                                    {entityTypeState ===
                                                      "Administrator" && (
                                                      <option value="Administrator">
                                                        {intl.formatMessage({
                                                          id: "user.profile.team.access.administrator",
                                                        })}
                                                      </option>
                                                    )}
                                                    {(entityTypeState ===
                                                      "Business" ||
                                                      entityTypeState ===
                                                        "Administrator") && (
                                                      <option value="Business">
                                                        {intl.formatMessage({
                                                          id: "user.profile.team.access.business",
                                                        })}
                                                      </option>
                                                    )}
                                                  </Select>
                                                </div>

                                                {edittedUser.permission
                                                  .entityType === "Store" &&
                                                  (entityTypeState ===
                                                    "Business" ||
                                                    entityTypeState ===
                                                      "Administrator") && (
                                                    <div className="detail-item--margin-bottom">
                                                      <h5>
                                                        {intl.formatMessage({
                                                          id: "user.profile.team.store",
                                                        })}
                                                      </h5>

                                                      <Select
                                                        required={true}
                                                        bgColors="white"
                                                        mode="named-child"
                                                        name="store"
                                                        value={
                                                          edittedUser.permission
                                                            .entityId
                                                        }
                                                        placeholder={intl.formatMessage(
                                                          {
                                                            id: "user.profile.team.store",
                                                          }
                                                        )}
                                                        handler={
                                                          changeEdittedUser
                                                        }
                                                      >
                                                        <option value="">
                                                          {intl.formatMessage({
                                                            id: "request.payment.select.label",
                                                          })}
                                                        </option>
                                                        {stores.map((store) => (
                                                          <option
                                                            key={store.id}
                                                            value={store.id}
                                                          >
                                                            {store.socialName}
                                                          </option>
                                                        ))}
                                                      </Select>

                                                      {editUserErrors.some(
                                                        (error: string) =>
                                                          error === "store"
                                                      ) ? (
                                                        <indexStyled.ErrorCompWithoutPadding>
                                                          {intl.formatMessage({
                                                            id: "settings.new.user.store.error",
                                                          })}
                                                        </indexStyled.ErrorCompWithoutPadding>
                                                      ) : null}
                                                    </div>
                                                  )}
                                              </>
                                            );
                                          })()
                                        ) : (
                                          <div></div>
                                        )}

                                        <styled.Bottom>
                                          <div className="edit-version">
                                            <Buttons
                                              mode="unfilled"
                                              label={intl.formatMessage({
                                                id: "user.profile.team.cancel.button.label",
                                              })}
                                              handler={cancelEdit}
                                            />
                                          </div>

                                          {(businessAccess === 0 ||
                                            storeAcces === 0) && (
                                            <div className="edit-version delete-user-button">
                                              <Buttons
                                                mode="unfilled"
                                                label={intl.formatMessage({
                                                  id: "user.profile.team.delete.user.button.label",
                                                })}
                                                handler={() =>
                                                  handleDeleteUser(
                                                    edittedUser.id,
                                                    edittedUser.permission
                                                      .entityType,
                                                    edittedUser.permission
                                                      .entityId
                                                  )
                                                }
                                              />
                                            </div>
                                          )}

                                          {/* {!isEditProcessing && <div className="header-right">
                                <Button label={intl.formatMessage({ id: "user.profile.ui.save.changes" })} icon={buttonEdit} handler={(event: React.SyntheticEvent) => updateUser(event, user.id)} />
                              </div>} */}
                                          {isEditProcessing && (
                                            <div className="header-right">
                                              <SmallProcessingButton />
                                            </div>
                                          )}
                                        </styled.Bottom>

                                        {errorMessageCreateUser !== "" && (
                                          <indexStyled.ErrorComp>
                                            {intl.formatMessage({
                                              id: errorMessageCreateUser,
                                            })}
                                          </indexStyled.ErrorComp>
                                        )}
                                      </indexStyled.EditUserContainer>
                                    </tr>
                                  )}
                              </>
                            );
                          })
                        ) : (
                          <Skeleton
                            count={1}
                            height={25}
                            className="skeleton"
                          />
                        )}
                      </tbody>
                    </indexStyled.FullTableUsers>

                    <Buttons
                      mode="filled"
                      label={intl.formatMessage({
                        id: "settings.store.create.user.label",
                      })}
                      handler={handleCreateNewUser}
                    />
                  </indexStyled.TableItems>
                </indexStyled.TableList>
              </styled.DetailsItems>
            )}
          </styled.BoxDetails>
        )}

        {/* <styled.BoxDetails>
          <styled.Header>
            <FormattedMessage id="settings.integrations.title" />

            <p className="subtitle">{intl.formatMessage({ id: "settings.business.information.text" })}</p>
          </styled.Header>
        </styled.BoxDetails> */}
      </styled.LeftSettings>

      <styled.Right>
        <styled.BoxDetails className="border-bottom">
          <styled.Header>
            <FormattedMessage id="user.profile.right.title" />

            <div className="tip-container">
              <div>
                <img src={tipIcon} alt="tip" />
                <p>{intl.formatMessage({ id: "manager.links.details.tip" })}</p>
              </div>

              <p>{intl.formatMessage({ id: "settings.stores.text" })}</p>
            </div>
          </styled.Header>

          <styled.DetailsItems>
            <indexStyled.TableList>
              <indexStyled.TableItems>
                {storesToList.length === 0 && isStoresLoaded && (
                  <h3>{intl.formatMessage({ id: "items.not.found" })}</h3>
                )}

                <indexStyled.FullTable>
                  <thead>
                    <tr className="remove--border-bottom">
                      <th>
                        <FormattedMessage id="settings.store.list.status" />
                      </th>
                      <th>
                        <FormattedMessage id="settings.store.list.name" />
                      </th>
                      <th>
                        <FormattedMessage id="settings.store.list.id" />
                      </th>
                      <th>
                        <FormattedMessage id="settings.store.list.created" />
                      </th>
                      <th></th>
                    </tr>
                  </thead>

                  <tbody>
                    {isStoresLoaded ? (
                      storesToList.map((store) => {
                        return (
                          <>
                            <tr
                              key={store.identificationNumber}
                              className={
                                visibleEditStore && visibleStoreId === store.id
                                  ? "remove--border-bottom"
                                  : ""
                              }
                            >
                              <td>
                                {store.status === "onboarding" ||
                                store.status === "pending" ? (
                                  <div className="status-pending">
                                    {intl.formatMessage({
                                      id: "status.pending",
                                    })}
                                  </div>
                                ) : (
                                  <div className="status-succeeded">
                                    {intl.formatMessage({
                                      id: "status.active",
                                    })}
                                  </div>
                                )}
                              </td>

                              <td>{store.socialName}</td>

                              {(() => {
                                let formattedIdentificationNumber = "";

                                if (store.identificationNumber.length > 11) {
                                  formattedIdentificationNumber =
                                    store.identificationNumber.replace(
                                      /^(\d{2})(\d{3})?(\d{3})?(\d{4})?(\d{2})?/,
                                      "$1.$2.$3/$4-$5"
                                    );
                                } else {
                                  formattedIdentificationNumber =
                                    store.identificationNumber.replace(
                                      /^(\d{3})(\d{3})(\d{3})(\d{2})/,
                                      "$1.$2.$3-$4"
                                    );
                                }

                                return <td>{formattedIdentificationNumber}</td>;
                              })()}

                              <td>10/10/2021</td>

                              <td>
                                <Buttons
                                  mode="unfilled"
                                  label={intl.formatMessage({
                                    id:
                                      visibleEditStore &&
                                      visibleStoreId === store.id
                                        ? "user.profile.ui.save"
                                        : "settings.team.list.edit",
                                  })}
                                  handler={() =>
                                    visibleEditStore &&
                                    visibleStoreId === store.id
                                      ? editStore(
                                          store.id,
                                          companyData.tradingName
                                        )
                                      : toggleEditBusiness(store.id, store)
                                  }
                                />

                                {/* { isProcessingStore && <Buttons 
                              mode="unfilled-processing" 
                              label="" 
                              handler={() => {}} 
                            />
                          } */}
                              </td>
                            </tr>

                            {visibleEditStore &&
                              visibleStoreId === store.id && (
                                <tr className="remove--border-bottom height--auto">
                                  <indexStyled.EditStoreContainer>
                                    <div className="detail-item--margin-bottom large-width">
                                      <h5>
                                        {intl.formatMessage({
                                          id: "user.profile.right.trading.name",
                                        })}
                                      </h5>

                                      <Input
                                        required={true}
                                        mode="named"
                                        name="tradingName"
                                        value={companyData.tradingName}
                                        placeholder=""
                                        handler={changeCompanyData}
                                      />
                                    </div>

                                    <div className="detail-item--margin-bottom margin-left">
                                      <h5>
                                        {intl.formatMessage({
                                          id: "user.profile.right.logo",
                                        })}
                                      </h5>

                                      <div className="store-logo-container">
                                        <label htmlFor="upload-logo">
                                          <img
                                            src={companyData.logoPath}
                                            alt="store logo"
                                          />
                                        </label>
                                        <input
                                          type="file"
                                          id="upload-logo"
                                          onChange={(e) => handleStoreLogo(e)}
                                        />
                                      </div>
                                    </div>
                                  </indexStyled.EditStoreContainer>
                                </tr>
                              )}
                          </>
                        );
                      })
                    ) : (
                      <Skeleton count={1} height={25} className="skeleton" />
                    )}
                  </tbody>
                </indexStyled.FullTable>

                {/* <Buttons mode="filled" label={intl.formatMessage({ id: "view.as.create.store" })} handler={() => { }} /> */}
              </indexStyled.TableItems>
            </indexStyled.TableList>
          </styled.DetailsItems>
        </styled.BoxDetails>

        {hasFees ? (
          <styled.BoxDetails>
            <styled.Header>
              <FormattedMessage id="user.profile.right.fees.title" />

              <p className="subtitle">
                {intl.formatMessage({ id: "settings.fees.text" })}{" "}
                <a href="#" id="intercomChatSettings">
                  {intl.formatMessage({ id: "settings.fees.text.link" })}
                </a>
                .
              </p>
            </styled.Header>

            <styled.DetailsItems>
              <NationsSelectButtonModal
                label1="financial.withdrawal.national"
                label2="global.fees"
                value1="brazil-finances"
                value2="global-finances"
                handler={setFeesType}
              />

              <div className="payment-method-container">
                <div className="tip-container--img-right">
                  <div>
                    <p>
                      {intl.formatMessage({ id: "settings.store.fee.boleto" })}
                    </p>

                    <TipButtonModal
                      text={intl.formatMessage({
                        id: "tip.settings.fees.boleto",
                      })}
                    />
                  </div>
                </div>
                <p>
                  {intl.formatNumber(boletoFees.fixedAmount, {
                    style: "currency",
                    currency: "BRL",
                  })}
                </p>
              </div>

              <div className="payment-method-container">
                <div className="tip-container--img-right">
                  <div>
                    <p>
                      {intl.formatMessage({ id: "settings.store.fee.pix" })}
                    </p>

                    <TipButtonModal
                      text={intl.formatMessage({ id: "tip.settings.fees.pix" })}
                    />
                  </div>
                </div>
                <p>{`${intl.formatMessage({ id: "fees.from" })} ${
                  pixFees.percentage ? pixFees.percentage.toFixed(2) : 0
                }% + ${intl.formatNumber(
                  pixFees.fixedAmount ? pixFees.fixedAmount : 0,
                  { style: "currency", currency: "BRL" }
                )}`}</p>
              </div>

              <div className="payment-method-container">
                <div className="tip-container--img-right tip-link">
                  <div>
                    <p>
                      {intl.formatMessage({ id: "settings.store.fee.credit" })}
                    </p>

                    <TipButtonModal
                      text={intl.formatMessage({
                        id: "tip.settings.fees.credit.card",
                      })}
                    />
                  </div>
                </div>
                {minFee && minFee.length > 0 ? (
                  <p
                    className="tip-link"
                    onClick={(e) => setIsFeeItemOpen(!isFeeItemOpen)}
                  >
                    {`${intl.formatMessage({ id: "fees.from" })} ${
                      minFee[0].percentage
                    }% + ${intl.formatNumber(minFee[0].amount, {
                      style: "currency",
                      currency: "BRL",
                    })}`}{" "}
                    <img src={arrowDownIcon} alt="arrow down" />
                  </p>
                ) : null}
              </div>

              {isFeeItemOpen &&
                userFeesObject &&
                minFee &&
                userFeesObject.map((item, index) => {
                  return (
                    <FeeItem
                      key={Math.random()}
                      items={item}
                      index={index}
                      minFee={minFee}
                    />
                  );
                })}
            </styled.DetailsItems>
          </styled.BoxDetails>
        ) : null}

        <styled.BoxDetails className="border-top hidden-desktop">
          <styled.Header>
            <FormattedMessage id="user.profile.page.title" />

            <p className="subtitle">
              {intl.formatMessage({ id: "user.profile.page.subtitle" })}
            </p>
          </styled.Header>
          <styled.DetailsItems>
            <div className="info-detail-item">
              <h5>{intl.formatMessage({ id: "user.profile.email" })}</h5>
              {!edittingUserData && <p>{personalData.username}</p>}
              {edittingUserData && (
                <Input
                  required={false}
                  mode="named"
                  name="username"
                  value={personalData.username}
                  placeholder=""
                  handler={changePersonalData}
                />
              )}
            </div>

            <div className="info-detail-item">
              <h5>{intl.formatMessage({ id: "user.profile.name" })}</h5>
              {!edittingUserData && <p>{personalData.name}</p>}
              {edittingUserData && (
                <Input
                  required={false}
                  mode="named"
                  name="username"
                  value={personalData.name}
                  placeholder=""
                  handler={changePersonalData}
                />
              )}
            </div>

            <div className="info-detail-item">
              <h5>
                {intl.formatMessage({
                  id: !edittingUserData
                    ? "user.profile.password"
                    : "user.profile.old.password",
                })}
              </h5>
              {!edittingUserData && <p>{personalData.pass}</p>}
              {edittingUserData && (
                <>
                  <Input
                    required={false}
                    mode="named"
                    name="oldPass"
                    value={passWords.oldPass}
                    placeholder=""
                    handler={changePersonalData}
                  />
                </>
              )}
            </div>

            {edittingUserData && (
              <div className="info-detail-item">
                <h5>
                  {intl.formatMessage({ id: "user.profile.new.password" })}
                </h5>
                <Input
                  required={false}
                  mode="named"
                  name="oldPass"
                  value={passWords.newPass}
                  placeholder=""
                  handler={changePersonalData}
                />
              </div>
            )}
          </styled.DetailsItems>

          {!edittingUserData && (
            <div>
              <Buttons
                mode="filled"
                label={intl.formatMessage({ id: "settings.team.list.edit" })}
                handler={() => setEdittingUserData(!edittingUserData)}
              />
            </div>
          )}

          {edittingUserData && (
            <div className="flex-buttons-container">
              {isProcessing ? (
                <Buttons mode="filled-processing" label="" handler={() => {}} />
              ) : (
                <Buttons
                  mode="filled"
                  label={intl.formatMessage({ id: "user.profile.ui.save" })}
                  handler={() => {}}
                />
              )}
              <Buttons
                mode="unfilled"
                label={intl.formatMessage({ id: "home.withdrawall.cancel" })}
                disable={isProcessing}
                handler={() => setEdittingUserData(false)}
              />
            </div>
          )}
        </styled.BoxDetails>
      </styled.Right>
    </styled.LeftRightContainer>
  );
};

export default SettingsDetails;
