import React, { useState, useRef, useEffect } from "react";
import { useIntl } from "react-intl";

//Services
import RequesPaymentService from "services/RequestPayment";

//Hooks
import useClickOutside from "../../../hooks/useClickOutside";

//Components
import Input from "../../Forms/InputFloatLabel";
import Buttons from "../../Forms/Buttons";

//Assets
import tipIcon from "assets/images/global/tip-icon.svg";
import arrowIcon from "assets/images/topbar/select-arrow.svg";

//Store
import { useRequestPaymentLinkStore } from "../../../store/requestPaymentLink";

import * as styled from "./modals-styles";

type Product = {
  id: number;
  name: string;
  quantity: number;
  classRoomIds: number[];
  unitPrice: number;
};

interface Plan {
  id: number;
  name: string;
  frequency: number;
  paymentTypes: number[];
  amount: number;
  gracePeriod: number;
  description: string;
  title: string;
  productCategory?: any;
  chargeDaysBefore?: number;
  maxCharges?: number;
  tax?: any;
  isVisible?: number;
  products?: Product[];
}

type Plans = {
  plans: Plan[];
  currentPage: number;
  limit: number;
  pages: number;
  total: number;
};

type Props = {
  handleCreatingPlan: any;
  setNewPaymentLink: any;
  newPaymentLink: any;
  setNewPlan: any;
  newPlan: any;
  setManualOrder?: any;
  isFromPaymentLink: boolean;
};

const SelectPlansButtonModal = ({
  handleCreatingPlan,
  setNewPaymentLink,
  newPaymentLink,
  setNewPlan,
  newPlan,
  setManualOrder,
  isFromPaymentLink,
}: Props) => {
  const { handleSelectedPlanBackup } = useRequestPaymentLinkStore();

  const [isMounted, setIsMounted] = useState(false);
  const [showRequestPaymentModal, setShowRequestPaymentModal] = useState(false);

  const initialPlan = {
    currentPage: 0,
    limit: 0,
    pages: 0,
    plans: [],
    total: 0,
  };

  const [plans, setPlans] = useState<Plans>(initialPlan);
  const [backupPlans, setBackupPlans] = useState<Plans>(initialPlan);
  const [wantedPlan, setWantedPlan] = useState("");

  const selectPlanModalRef = useRef<HTMLDivElement>(null);
  const intl = useIntl();

  useEffect(() => {
    if (wantedPlan !== "") {
      let filteredPlans: Plan[] = backupPlans?.plans?.filter((plan: Plan) =>
        plan.name.toLowerCase().includes(wantedPlan.toLowerCase())
      );

      setPlans({
        ...backupPlans,
        plans: filteredPlans,
      });
    }

    if (wantedPlan === "") {
      setPlans(backupPlans);
    }
  }, [wantedPlan]);

  useEffect(() => {
    RequesPaymentService.requestPlans().then((response) => {
      setBackupPlans(response.data);
      setPlans(response.data);
    });
  }, []);

  function handleShowModal() {
    if (selectPlanModalRef.current) {
      setIsMounted(false);
      setShowRequestPaymentModal(true);
    } else {
      setIsMounted(true);
      setShowRequestPaymentModal(true);
    }
  }

  function handleSelectPlan(event: number) {
    setIsMounted(!isMounted);

    setTimeout(() => {
      setShowRequestPaymentModal(false);
    }, 300);

    let selectedPlan: Plan | undefined = plans.plans?.find((plan: Plan) => {
      return plan.id === event;
    });

    if (selectedPlan) {
      handleCreatingPlan(false)
      setNewPaymentLink((prevState: any) => {
        return {
          ...prevState,
          planId: selectedPlan!.id,
          name: selectedPlan!.name,
          title: selectedPlan!.name,
          amount: selectedPlan!.amount,
          frequency: selectedPlan!.frequency.toString(),
          gracePeriod: selectedPlan!.gracePeriod,
          description: selectedPlan!.name,
          maxCharges: selectedPlan!.maxCharges,
          taxAmount: selectedPlan!.tax?.amount,
          taxMode: selectedPlan!.tax?.mode,
          products: selectedPlan!.products,
        };
      });
      
      handleSelectedPlanBackup({
        gracePeriod: selectedPlan!.gracePeriod!,
        maxCharges: selectedPlan!.maxCharges!,
        chargeDaysBefore: selectedPlan!.chargeDaysBefore!,
      });
      
      if (!isFromPaymentLink) {
        setManualOrder((prevState: any) => {
          return {
            ...prevState,
            amount: selectedPlan!.amount,
            planId: selectedPlan!.id,
            name: selectedPlan!.name,
            maxCharges: selectedPlan!.maxCharges,
          };
        });
      }
      console.log(selectedPlan!.id, selectedPlan!.name, "e esse nome")
    }
  }

  function createANewPlan() {
    setIsMounted(!isMounted);

    setTimeout(() => {
      setShowRequestPaymentModal(false);
    }, 300);

    handleCreatingPlan(true);
  }

  useClickOutside(selectPlanModalRef, isMounted, setIsMounted);

  return (
    <styled.RelativeContainer>
      <styled.LabelSelectPlan onClick={handleShowModal}>
        {!newPaymentLink.name && (
          <p>{intl.formatMessage({ id: "request.payment.select.plan" })}</p>
        )}
        {newPaymentLink.name !== "" && <p>{newPaymentLink.name}</p>}

        <img src={arrowIcon} alt="arrow" />
      </styled.LabelSelectPlan>

      {showRequestPaymentModal && (
        <styled.SelectPlansContainer
          style={
            isMounted
              ? { animation: "scaleModal .2s ease forwards" }
              : { animation: "reveserScameModal .1s ease-out forwards" }
          }
          ref={selectPlanModalRef}
          onAnimationEnd={() => {
            if (!isMounted) setShowRequestPaymentModal(false);
          }}
        >
          <div className="header-container">
            <div>
              <Input
                required={false}
                mode="search"
                name="wantedStore"
                value={wantedPlan}
                placeholder={intl.formatMessage({
                  id: "view.as.search.establishment",
                })}
                handler={setWantedPlan}
              />
            </div>
          </div>

          <div className="buttons-container">
            {plans &&
              plans.plans.map((plan) => {
                let frequency;
                switch (plan.frequency) {
                  case 0:
                    frequency = "request.payment.plan.daily.simple";
                    break;
                  case 1:
                    frequency = "request.payment.plan.weekly.simple";
                    break;
                  case 2:
                    frequency = "request.payment.plan.monthly.simple";
                    break;
                  case 3:
                    frequency = "request.payment.plan.quarterly.simple";
                    break;
                  case 4:
                    frequency = "request.payment.plan.halfYearly.simple";
                    break;
                  case 5:
                    frequency = "request.payment.plan.yearly.simple";
                    break;
                  default:
                    frequency = "";
                    break;
                }

                if (
                  plan.isVisible === 1 ||
                  plan.isVisible === undefined ||
                  plan.isVisible === null
                ) {
                  return (
                    <div
                      className="button__item"
                      key={plan.id}
                      onClick={() => handleSelectPlan(plan.id)}
                    >
                      <Buttons
                        mode="simple"
                        label={plan.name}
                        handler={() => {}}
                      />
                      <span>
                        {intl.formatNumber(plan.amount, {
                          style: "currency",
                          currency: "BRL",
                        })}{" "}
                        / {intl.formatMessage({ id: frequency })}
                      </span>
                    </div>
                  );
                }
              })}
          </div>

          <div className="create-store-container">
            <Buttons
              mode="unfilled"
              label={intl.formatMessage({ id: "request.payment.create.plan" })}
              handler={createANewPlan}
            />
          </div>

          <styled.Tip>
            <div className="tip-intern-container">
              <div>
                <img src={tipIcon} alt="tip" />
                <p>{intl.formatMessage({ id: "request.payment.tip.title" })}</p>
              </div>

              <p>{intl.formatMessage({ id: "plan.select.tip.text" })}</p>
            </div>
          </styled.Tip>
        </styled.SelectPlansContainer>
      )}
    </styled.RelativeContainer>
  );
};

export default SelectPlansButtonModal;
