import React, { useState, useRef, useEffect } from "react";
import { useIntl } from "react-intl";
import { useHistory } from "react-router-dom";

import useClickOutside from "../../../hooks/useClickOutside";

//Components
import Buttons from "../../Forms/Buttons";

//Assets
import tipIcon from "assets/images/global/tip-icon.svg";

//Styles
import * as styled from "./modals-styles";

const RequestPaymentButtonModal = (props: any) => {
  const [isMounted, setIsMounted] = useState(false);
  const [showRequestPaymentModal, setShowRequestPaymentModal] = useState(false);

  const roles = ["Owner", "Administrator", "Agent", "ViewOnly", "API"];

  const [accessLevel, setAccessLevel] = useState<number | undefined>(() => {
    const session = JSON.parse(localStorage.getItem("session") || "{}");

    if (session.role) {
      return roles.indexOf(session.role.split(".")[1]);
    }

    return undefined;
  });

  const [storeId, setStoreId] = useState(() => {
    const session = JSON.parse(localStorage.getItem("session") || "{}");

    if (session.storeId) {
      return session.storeId;
    }

    return 0;
  });

  const requestPaymentRef = useRef<HTMLDivElement>(null);
  const intl = useIntl();
  const history = useHistory();

  function handleShowModal() {
    if (requestPaymentRef.current) {
      setIsMounted(false);
      setShowRequestPaymentModal(true);
    } else {
      setIsMounted(true);
      setShowRequestPaymentModal(true);
    }
  }

  function goToPaymentLinks() {
    history.push("/payment-links");

    setIsMounted(!isMounted);

    setTimeout(() => {
      setShowRequestPaymentModal(false);
    }, 300);
  }

  function goToCreatePaymentLink() {
    history.push("/request-payment/create-payment-link");

    setIsMounted(!isMounted);

    setTimeout(() => {
      setShowRequestPaymentModal(false);
    }, 300);
  }

  function goToCreateManualPayment() {
    history.push("/request-payment/create-manual-payment");

    setIsMounted(!isMounted);

    setTimeout(() => {
      setShowRequestPaymentModal(false);
    }, 300);
  }

  useClickOutside(requestPaymentRef, isMounted, setIsMounted);

  return (
    <styled.RelativeContainer>
      {storeId &&
      storeId > 0 &&
      accessLevel !== undefined &&
      accessLevel !== 3 ? (
        <Buttons
          mode="filled"
          label={intl.formatMessage({ id: "request.payment.button.label" })}
          handler={handleShowModal}
        />
      ) : null}

      {showRequestPaymentModal && (
        <styled.RequestPaymentContainer
          style={
            isMounted
              ? { animation: "scaleModal .2s ease forwards" }
              : { animation: "reveserScameModal .1s ease-out forwards" }
          }
          ref={requestPaymentRef}
          onAnimationEnd={() => {
            if (!isMounted) setShowRequestPaymentModal(false);
          }}
        >
          <div className="buttons-container">
            <div className="button__item">
              <Buttons
                mode="simple"
                label={intl.formatMessage({
                  id: "request.payment.modal.button.payment.link",
                })}
                handler={goToCreatePaymentLink}
              />
            </div>
            <div className="button__item">
              <Buttons
                mode="simple"
                label={intl.formatMessage({
                  id: "request.payment.modal.button.manually",
                })}
                handler={goToCreateManualPayment}
              />
            </div>
            <div className="button__item">
              <Buttons
                mode="simple"
                label={intl.formatMessage({
                  id: "request.payment.modal.button.list.links",
                })}
                handler={goToPaymentLinks}
              />
            </div>
          </div>

          <styled.Tip>
            <div className="tip-intern-container">
              <div>
                <img src={tipIcon} alt="tip" />
                <p>{intl.formatMessage({ id: "request.payment.tip.title" })}</p>
              </div>

              <p>{intl.formatMessage({ id: "request.payment.tip.text" })}</p>
            </div>
          </styled.Tip>
        </styled.RequestPaymentContainer>
      )}
    </styled.RelativeContainer>
  );
};

export default RequestPaymentButtonModal;
